import React from 'react';
import SoldEquipment from '../../Components/Sold Equipment/soldEquipment';

const SoldBackhoe = () => {
    return (
        <div>
            <SoldEquipment cat="Backhoe" name="backhoe"/>
        </div>
    );
}

export default SoldBackhoe;
