import React, { useEffect } from "react";
import "./whyGces.css";
import Brands from "../../Components/Brands/brands";
import Services from "../../Components/Services/services";
import { scrollToTop } from "../../functions/scrollToTop";

const WhyMyEquipment = () => {
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <>
      {/* Why GCES Eqyipment section */}
      <section className="single_equipment py-lg-5 py-4">
        <div className="container">
          <div className="navigation_border mb-lg-4 mb-3 aos-init aos-animate">
            <div className="row">
              <div className="col-md-12 text-md-right text-center pb-lg-3 d-none d-sm-block">
                <ul className="sort_breadcrums">
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>Why Gces</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="content_area">
            <div className="img_border mb-4 text-center aos-init aos-animate">
              <img
                src="/images/why GCES Banner.jpg"
                alt="Why My Equipment"
                className="img-banner lazyloaded"
              />
            </div>
            <h3 className="mini_border_title pt-2 aos-init aos-animate">
              WHY GCES?
            </h3>
            <p className="aos-init aos-animate">
              GCES is a turn-key equipment provider that specializes in the
              purchasing, sale, and export logistics of pre-owned cost-effective
              construction equipment.
            </p>
            <p className="aos-init aos-animate">
              We help contractors and dealers in the United States and Canada
              with their equipment needs, as well as overseas clients in Europe,
              Latin America, Africa, Southeast Asia, and the Middle East.
            </p>
            <p className="aos-init aos-animate">
              Also, we help wholesalers client to acquire their wanted machines,
              we provide a complete series of heavy machinery, including major
              manufacturers like Caterpillar, Volvo, Komatsu, Hitachi, and John
              Deere.
            </p>
            <p className="aos-init aos-animate">
              GCES will providing your company with different choices for buying
              and selling used heavy equipment with more profitable ways to
              manage your equipment assets.
            </p>
            <h3 className="mini_border_title pt-2 aos-init aos-animate">
              FAST SERVICES
            </h3>
            <p className="aos-init aos-animate">
              You will receive fast and dependable trade-in offers. With a large
              local and international buyer network, we provide several channels
              for both pre-owned branded equipment and off-brand trades.
            </p>
            <h3 className="mini_border_title pt-2 aos-init aos-animate">
              SHIPPING
            </h3>
            <p className="aos-init aos-animate">
              In addition to trades in machinery, we can help our clients with
              inland transportation for local market buyers and shipping
              overseas for international buyers. Also, inquire about renting our
              equipment.
            </p>

            <div className="aos-init aos-animate">
              <span style={{ fontSize: "14px", fontWeight: "600" }}>
                IF YOU HAVE ANY HEAVY EQUIPMENT THAT YOU NEED TO SELL CALL US
                TODAY AT <br />
                <a
                  href=""
                  style={{
                    color: "#273846",
                    fontWeight: "bold",
                  }}
                >
                  <i
                    className="bi bi-telephone-fill mx-1"
                    style={{
                      color: "#ffb500",
                      fontSize: "19px",
                    }}
                  ></i>{" "}
                  +1 (657) 347-6660
                </a>{" "}
                OR
                <a
                  href=""
                  style={{
                    color: "#273846",
                    fontWeight: "bold",
                  }}
                >
                  <i
                    className="bi bi-envelope mx-1"
                    style={{
                      color: "#ffb500",
                      fontSize: "19px",
                    }}
                  ></i>{" "}
                  advertising@gcesequipment.com
                </a>
              </span>
            </div>
          </div>
        </div>
      </section>

      {/* Our Service  */}
      <Services />

      {/* Our Brands */}
      <Brands />
    </>
  );
};

export default WhyMyEquipment;
