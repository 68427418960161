import React from "react";
import Services from "../../Components/Services/services";
import Brands from "../../Components/Brands/brands";

const Transport = () => {
  return (
    <>
      {/* Single Service  */}
      <section className="single_equipment py-lg-5 py-4">
        <div className="container">
          <div
            className="navigation_border mb-lg-4 mb-3 aos-init aos-animate"
            data-aos="fade-up"
          >
            <div className="row">
              <div className="col-lg-6 text-lg-left text-center pb-lg-3 pb-2">
                {/* <div className="navigation_btn">
                                <a href="" className="mr-2"><i className="bi bi-chevron-left fw-bold" /> heavy equipment dismantling </a> |
                                <a href="inspection-and-appraisal" className="ml-2"> Inspection &amp; Appraisal <i className="bi bi-chevron-right ml-2" /> </a>
                            </div> */}
              </div>
              <div className="col-md-6 text-md-right text-center pb-lg-3 d-none d-sm-block">
                <ul className="sort_breadcrums">
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>Transport</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="an-content-banner-transport aos-init aos-animate"></div>
          {/* service_content section */}
          <div className="service_content">
            <div className=" mb-4 text-center aos-init aos-animate">
              {/* <img src="./images/transport.jpg" alt="Service" className=' lazyloaded img-banner' /> */}
            </div>
            <div className="aos-init aos-animate">
              <h2 className="aos-init aos-animate">
                Construction equipment transport now
              </h2>
              <p className="aos-init aos-animate">
                Our used construction equipment could be sent to any area in the
                United States. We provide comprehensive transportation for
                construction, agricultural, and industrial machines. We can also
                transport our equipment to any overseas destination after you
                acquire it. Contact our shipping staff for further information.
              </p>
              <p className="aos-init aos-animate">
                In addition to transporting the gear we offer, we also arrange
                transportation for your current equipment. Whether you're
                switching work, moving machinery to another location, or
                returning to the workshop for repairs and maintenance, we can
                assist you in transporting those bulky vehicles and machines
                securely.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* More Service  */}
      <Services />

      {/* Our Brands */}
      <Brands />
    </>
  );
};

export default Transport;
