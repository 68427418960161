import React from 'react';
import SoldEquipment from '../../Components/Sold Equipment/soldEquipment';

const SoldMiniExcavator = () => {
    return (
        <div>
            <SoldEquipment cat="Mini Excavator" name="mini-excavator" />
        </div>
    );
}

export default SoldMiniExcavator;
