import React from 'react';
import SoldEquipment from '../../Components/Sold Equipment/soldEquipment';

const SoldDozer = () => {
    return (
        <div>
            <SoldEquipment cat="Dozer" name="dozer" />
        </div>
    );
}

export default SoldDozer;
