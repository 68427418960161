import React from 'react';
import './services.css';

import Brands from '../../Components/Brands/brands';
import Services from '../../Components/Services/services';

const Shipping = () => {

    return (
        <>
            {/* Single Service  */}
            <section className='single_equipment py-lg-5 py-4'>
                <div className="container">
                    <div className="navigation_border mb-lg-4 mb-3 aos-init aos-animate" data-aos="fade-up">
                        <div className="row">
                            <div className="col-lg-6 text-lg-left text-center pb-lg-3 pb-2">
                                {/* <div className="navigation_btn">
                                    <a href="" className="mr-2"><i className="bi bi-chevron-left fw-bold" /> heavy equipment dismantling </a> |
                                    <a href="inspection-and-appraisal" className="ml-2"> Inspection &amp; Appraisal <i className="bi bi-chevron-right ml-2" /> </a>
                                </div> */}
                            </div>
                            <div className="col-md-6 text-md-right text-center pb-lg-3 d-none d-sm-block">
                                <ul className="sort_breadcrums">
                                    <li><a href="/">Home</a></li>
                                    <li>Shipping</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* service_content section */}

                    <div className='an-content-banner-shipping aos-init aos-animate'>
                    </div>
                    
                    <div className='service_content'>
                        <div className=' mb-4 text-center aos-init aos-animate'>
                            {/* <img src="./images/shipping.jpg" alt="Service" className=' lazyloaded img-banner' /> */}
                        </div>
                        <div className='aos-init aos-animate'>
                            <h2 className='aos-init aos-animate'>
                                Construction equipment shipping now
                            </h2>
                            <p className='aos-init aos-animate'>
                                Contact the GCES team right away if you need a fast, dependable, and safe way to move construction equipment throughout the world. We provide worldwide shipping for construction machinery. This cost-effective technique of relocating your equipment assures that you have the necessary tools and trucks for your next contract, no matter where it is. We may also utilize our shipping service to ship purchases of secondhand equipment from our large inventory.
                            </p>

                        </div>
                    </div>

                </div>

            </section>

            {/* More Service  */}
            <Services />

            {/* Our Brands */}
            <Brands />
        </>
    );
}

export default Shipping;
