import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import ListProductsSlice from "../../store/action/listProductsSlice";
import ListCategoriesBrandsSlice from "../../store/action/listCategories-BrandsSlice";
import { useNavigate } from "react-router-dom";
import Brands from "../../Components/Brands/brands";
import Spinner from "../../Components/Spinner/spinner";
import { scrollToTop } from "../../functions/scrollToTop";
import ProductCard from "../../Components/ProductCard/productCard";

export default function Hitachi() {
  const [products, setProducts] = useState([]);
  const [soldProducts, setSoldProducts] = useState([]);
  const [brands, setBrands] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  const brandsAndCats = useSelector((state) => state.categoriesBrands);
  const productsList = useSelector((state) => state.productsList);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getAllProducts = async () => {
    if (!productsList) {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/products?limit=10&page=${page}&search=&category=&brand=Hitachi&model&year=`
      );
      dispatch(ListProductsSlice(res.data));
      setIsLoading(false);
      if (res.data.length < 10) {
        setHasMore(false);
      }
      for (let index = 0; index < res.data.length; index++) {
        if (res.data[index].sold === false) {
          // console.log("yes");
          setProducts((prevProducts) => [...prevProducts, res.data[index]]);
        } else if (res.data[index].sold === true) {
          setSoldProducts((prevSoldProducts) => [
            ...prevSoldProducts,
            res.data[index],
          ]);
        }
        if (res.data.length < 10) {
          setHasMore(false);
        }
      }
    } else if (!page == 0) {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/products?limit=10&page=${page}&search=&category=&brand=Hitachi&model&year=`
      );
      dispatch(ListProductsSlice(res.data));
      setIsLoading(false);
      if (res.data.length < 10) {
        setHasMore(false);
      }
      for (let index = 0; index < res.data.length; index++) {
        if (res.data[index].sold === false) {
          // console.log("yes");
          setProducts((prevProducts) => [...prevProducts, res.data[index]]);
        } else if (res.data[index].sold === true) {
          setSoldProducts((prevSoldProducts) => [
            ...prevSoldProducts,
            res.data[index],
          ]);
        }
      }
    }
  };

  const loadMore = () => {
    if (hasMore) {
      setPage((prevPage) => prevPage + 1); // Increment page number
    }
  };
  useEffect(() => {
    scrollToTop();
  }, []);
  useEffect(() => {
    getAllProducts();
    // getBrandsAndCats();
  }, [page]);

  return (
    <>
      <section className="hitachi-banner">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 align-self-center">
              <div
                className="banner_padding carousel carousel-fade slide"
                id="heroCarousel"
                data-ride="carousel"
              >
                <div
                  id="carouselExample Autoplaying"
                  className="carousel slide"
                  data-bs-ride="carousel"
                >
                  <div className="carousel-inner">
                    <div className="carousel-item active" style={{}}>
                      <div className="carousel-container">
                        <h2 className="lg_text animate__animated animate__fadeInLeft animate__slow">
                          EXPLORE WIDE
                          <span className="d-block">RANGE OF Hitachi </span>
                          <span className="d-block">EQUIPMENT</span>
                        </h2>
                        <div className="divider  animate__animated animate__fadeInLeft animate__delay-0.5s"></div>
                        {/* <h1 className="md_text aos-init aos-animate" data-aos="fade-right" data-aos-delay={600} data-aos-duration={1500} style={{ fontWeight: 'normal' }}>Used Equipment For Sale</h1> */}
                        <p className="md_text aos-init aos-animate">
                          <span className="mr-3">
                            {brandsAndCats &&
                              brandsAndCats.brands.map((brand, index) => {
                                if (brand.name === "Hitachi") {
                                  return (
                                    <img
                                      key={index}
                                      src={`${process.env.REACT_APP_SERVER_URL}/${brand.image}`}
                                      alt={brand.name}
                                      width={85}
                                      height={38}
                                      className=" lazyloaded mx-2"
                                    />
                                  );
                                }
                                return null;
                              })}
                          </span>{" "}
                          Hitachi
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="inventory_section py-5"
        style={{ backgroundImage: "none" }}
      >
        <div className="container">
          <div className="title_with_sep text-center aos-init aos-animate">
            <h3>Used Hitachi Construction Equipment</h3>
            <div className="divider m-auto"></div>
            <div className="row mx-10 aos-init aos-animate">
              <div className="col-md-8 text-md-left text-center pb-3 px-10">
                <p className="sorting_text">Showing 1 - {products.length}</p>
              </div>
              <div className="col-md-4 text-md-right text-center pb-3 px-10">
                <ul className="sort_breadcrums">
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>Hitachi</li>
                </ul>
              </div>
            </div>
            {isLoading ? (
              <Spinner />
            ) : (
              <ProductCard products={products} />
            )}
            <div className="text-center">
              {hasMore && (
                <div className="inventory_section-btn text-center">
                  <span className="cbtn_dark mt-20 cursor" onClick={loadMore}>
                    Load More
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>

      {/* Our Brands */}
      <Brands />
    </>
  );
}
