import React from 'react';
import './subscribe.css';
import { handleAlert } from '../../functions/handleAlert';
import axios from 'axios';
import { useState } from 'react';

const Subscribe = () => {
    const [showButton, setShowButton] = useState(false);

    const subscribeForm = async(event) => {
        event.preventDefault();
        let email = event.target.subscribeMail.value;
        // console.log(email);
        setShowButton(true)
        try {
            const res = await axios.post(`${process.env.REACT_APP_SERVER_URL}/subscription`,
                {
                    "email": email,
                })
            handleAlert(res.data.message, "success");
            // console.log(res.data);
            setShowButton(false);

        } catch (error) {
            handleAlert(error.response.data.message, "error");

        }
    }
    return (
        <section className='subscribe_section pb-3 aos-init aos-animate'>
            <div className="container">
                <div className="row">
                    <div className="col-lg-5">
                        <h3>
                            BE THE FIRST TO GET <br />
                            OUR GREAT DEALS
                        </h3>
                    </div>
                    <div className='col-lg-7 align-self-center'>
                        <form onSubmit={subscribeForm}>
                            <div className="row">
                                <div className='col-lg-12'>
                                    <div className='sub_input'>
                                        <i className="bi bi-envelope"></i>
                                        <input type="email" name="subscribeMail" id="" placeholder='Enter email to subscribe' required />
                                    </div>

                                </div>
                            </div>
                            <div className='sub_inp-submit aos-init aos-animate'>
                                <div className="row">
                                    <div className="col-lg-5"></div>
                                    <div className="col-lg-7">
                                    {!showButton ? <button type='submit'>
                                            <i className="bi bi-send-fill"></i> Subscribe
                                        </button> : <button type='submit'>
                                            <div className="spinner-border" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        </button>}
                                        
                                        {/* <button type='submit'>
                                            <i className="bi bi-send-fill"></i> Subscribe
                                        </button> */}
                                    </div>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </section>
    );
}

export default Subscribe;
