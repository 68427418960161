import React, { useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

import "./contactUs.css";
import { useEffect } from "react";
import { handleAlert } from "../../functions/handleAlert";
import { scrollToTop } from "../../functions/scrollToTop";

const ContactUs = () => {
  const [showButton, setShowButton] = useState(false);

  // all country
  var countries = {
    afghanistan: "Afghanistan",
    albania: "Albania",
    algeria: "Algeria",
    andorra: "Andorra",
    angola: "Angola",
    antigua_and_barbuda: "Antigua and Barbuda",
    argentina: "Argentina",
    armenia: "Armenia",
    australia: "Australia",
    austria: "Austria",
    azerbaijan: "Azerbaijan",
    bahamas: "Bahamas",
    bahrain: "Bahrain",
    bangladesh: "Bangladesh",
    barbados: "Barbados",
    belarus: "Belarus",
    belgium: "Belgium",
    belize: "Belize",
    benin: "Benin",
    bhutan: "Bhutan",
    bolivia: "Bolivia",
    bosnia_and_herzegovina: "Bosnia and Herzegovina",
    botswana: "Botswana",
    brazil: "Brazil",
    brunei: "Brunei",
    bulgaria: "Bulgaria",
    burkina_faso: "Burkina Faso",
    burundi: "Burundi",
    cabo_verde: "Cabo Verde",
    cambodia: "Cambodia",
    cameroon: "Cameroon",
    canada: "Canada",
    central_african_republic: "Central African Republic",
    chad: "Chad",
    chile: "Chile",
    china: "China",
    colombia: "Colombia",
    comoros: "Comoros",
    congo_democratic_republic_of_the: "Congo, Democratic Republic of the",
    congo_republic_of_the: "Congo, Republic of the",
    costa_rica: "Costa Rica",
    croatia: "Croatia",
    cuba: "Cuba",
    cyprus: "Cyprus",
    czech_republic: "Czech Republic",
    denmark: "Denmark",
    djibouti: "Djibouti",
    dominica: "Dominica",
    dominican_republic: "Dominican Republic",
    east_timor: "East Timor",
    ecuador: "Ecuador",
    egypt: "Egypt",
    el_salvador: "El Salvador",
    equatorial_guinea: "Equatorial Guinea",
    eritrea: "Eritrea",
    estonia: "Estonia",
    eswatini: "Eswatini",
    ethiopia: "Ethiopia",
    fiji: "Fiji",
    finland: "Finland",
    france: "France",
    gabon: "Gabon",
    gambia: "Gambia",
    georgia: "Georgia",
    germany: "Germany",
    ghana: "Ghana",
    greece: "Greece",
    grenada: "Grenada",
    guatemala: "Guatemala",
    guinea: "Guinea",
    guinea_bissau: "Guinea-Bissau",
    guyana: "Guyana",
    haiti: "Haiti",
    honduras: "Honduras",
    hungary: "Hungary",
    iceland: "Iceland",
    india: "India",
    indonesia: "Indonesia",
    iran: "Iran",
    iraq: "Iraq",
    ireland: "Ireland",
    israel: "Israel",
    italy: "Italy",
    ivory_coast: "Ivory Coast",
    jamaica: "Jamaica",
    japan: "Japan",
    jordan: "Jordan",
    kazakhstan: "Kazakhstan",
    kenya: "Kenya",
    kiribati: "Kiribati",
    korea_north: "Korea, North",
    korea_south: "Korea, South",
    kosovo: "Kosovo",
    kuwait: "Kuwait",
    kyrgyzstan: "Kyrgyzstan",
    laos: "Laos",
    latvia: "Latvia",
    lebanon: "Lebanon",
    lesotho: "Lesotho",
    liberia: "Liberia",
    libya: "Libya",
    liechtenstein: "Liechtenstein",
    lithuania: "Lithuania",
    luxembourg: "Luxembourg",
    madagascar: "Madagascar",
    malawi: "Malawi",
    malaysia: "Malaysia",
    maldives: "Maldives",
    mali: "Mali",
    malta: "Malta",
    marshall_islands: "Marshall Islands",
    mauritania: "Mauritania",
    mauritius: "Mauritius",
    mexico: "Mexico",
    micronesia: "Micronesia",
    moldova: "Moldova",
    monaco: "Monaco",
    mongolia: "Mongolia",
    montenegro: "Montenegro",
    morocco: "Morocco",
    mozambique: "Mozambique",
    myanmar: "Myanmar",
    namibia: "Namibia",
    nauru: "Nauru",
    nepal: "Nepal",
    netherlands: "Netherlands",
    new_zealand: "New Zealand",
    nicaragua: "Nicaragua",
    niger: "Niger",
    nigeria: "Nigeria",
    north_macedonia: "North Macedonia",
    norway: "Norway",
    oman: "Oman",
    pakistan: "Pakistan",
    palau: "Palau",
    panama: "Panama",
    papua_new_guinea: "Papua New Guinea",
    paraguay: "Paraguay",
    peru: "Peru",
    philippines: "Philippines",
    poland: "Poland",
    portugal: "Portugal",
    qatar: "Qatar",
    romania: "Romania",
    russia: "Russia",
    rwanda: "Rwanda",
    saint_kitts_and_nevis: "Saint Kitts and Nevis",
    saint_lucia: "Saint Lucia",
    saint_vincent_and_the_grenadines: "Saint Vincent and the Grenadines",
    samoa: "Samoa",
    san_marino: "San Marino",
    sao_tome_and_principe: "Sao Tome and Principe",
    saudi_arabia: "Saudi Arabia",
    senegal: "Senegal",
    serbia: "Serbia",
    seychelles: "Seychelles",
    sierra_leone: "Sierra Leone",
    singapore: "Singapore",
    slovakia: "Slovakia",
    slovenia: "Slovenia",
    solomon_islands: "Solomon Islands",
    somalia: "Somalia",
    south_africa: "South Africa",
    south_sudan: "South Sudan",
    spain: "Spain",
    sri_lanka: "Sri Lanka",
    sudan: "Sudan",
    suriname: "Suriname",
    sweden: "Sweden",
    switzerland: "Switzerland",
    syria: "Syria",
    taiwan: "Taiwan",
    tajikistan: "Tajikistan",
    tanzania: "Tanzania",
    thailand: "Thailand",
    togo: "Togo",
    tonga: "Tonga",
    trinidad_and_tobago: "Trinidad and Tobago",
    tunisia: "Tunisia",
    turkey: "Turkey",
    turkmenistan: "Turkmenistan",
    tuvalu: "Tuvalu",
    uganda: "Uganda",
    ukraine: "Ukraine",
    united_arab_emirates: "United Arab Emirates",
    united_kingdom: "United Kingdom",
    united_states_of_america: "United States of America",
    uruguay: "Uruguay",
    uzbekistan: "Uzbekistan",
    vanuatu: "Vanuatu",
    vatican_city: "Vatican City",
    venezuela: "Venezuela",
    vietnam: "Vietnam",
    yemen: "Yemen",
    zambia: "Zambia",
    zimbabwe: "Zimbabwe",
  };

  const dispatch = useDispatch();

  const sendRequest = async (event) => {
    setShowButton(true);
    event.preventDefault();
    let name = event.target.name.value;
    let email = event.target.email.value;
    let phone = event.target.phone.value;
    let companyName = event.target.companyName.value;
    let country = event.target.country.value;
    // let cat = event.target.category.value;
    // let model = event.target.model.value;
    let subject = event.target.subject.value;
    let message = event.target.message.value;
    // let brand = event.target.brand.value;
    // let price = parseInt(event.target.price.value);
    // console.log(cat, subject, model, phone, email, message, companyName, country, name);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/contact-us`,
        {
          name: name,
          email: email,
          phone: phone,
          companyName: companyName,
          country: country,
          equipment: "",
          message: message,
          model: "",
          subject: subject,
          brand: "",
          // "year": year
        }
      );
      handleAlert(res.data.message, "success");
      setShowButton(false);
    } catch (error) {
      handleAlert(error.response.data.message, "error");
    }
  };


  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <main>
      <section className="brands_logos py-5 brands_logos1">
        <div className="container aos-init aos-animate">
          <h3 className="border_title txt_dark">Contact us</h3>
          <section className="main-section p-3">
            <div className="container">
              <div className="row main-area">
                <div className="col-8 offset-2 dashed-line">&nbsp;</div>
                <div className="clearfix"></div>
                <div className="col-lg-4  col-md-4">
                  <div className="heading-area text-center">
                    <h2 className="heading">1</h2>
                  </div>
                  <p className="paragraph">
                    Send us an inquiry about the equipment you need; the Type,
                    Capacity, Location and Year.
                  </p>
                </div>
                <div className="col-lg-4 col-md-4 ">
                  <div className="heading-area text-center">
                    <h2 className="heading">2</h2>
                  </div>
                  <p className="paragraph">
                    Once we receive your request, we will contact you within two
                    working days.
                  </p>
                </div>
                <div className="col-lg-4 col-md-4 ">
                  <div className="heading-area text-center">
                    <h2 className="heading">3</h2>
                  </div>
                  <p className="paragraph">
                    Depending on what we have available in our inventory, we
                    will offer you the equipment you are searching for.
                  </p>
                </div>
              </div>
            </div>
          </section>
          <div className="row contact-sec">
            <div className="col-lg-12">
              <div>
                <form onSubmit={sendRequest}>
                  <div className="row">
                    <div className="col-lg-4 mb-3">
                      <label className="text_dark mb-2">Name</label>
                      <input
                        type="text"
                        name="name"
                        id="user-name"
                        placeholder="Your Name"
                        className="formcontrol"
                        required
                      />
                    </div>
                    <div className="col-lg-4 mb-3">
                      <label className="text_dark mb-2">Email</label>
                      <input
                        type="email"
                        name="email"
                        id="user-email"
                        placeholder="Your Email"
                        className="formcontrol"
                        required
                      />
                    </div>
                    <div className="col-lg-4 mb-3">
                      <label className="text_dark mb-2">Phone</label>
                      <input
                        type="text"
                        name="phone"
                        id="user-phone"
                        placeholder="Phone Number"
                        className="formcontrol"
                      />
                    </div>
                    <div className="col-lg-4 mb-3">
                      <label className="text_dark mb-2">Company Name</label>
                      <input
                        type="text"
                        name="companyName"
                        id="companyName"
                        placeholder="Company Name"
                        className="formcontrol"
                        required
                      />
                    </div>
                    <div className="col-lg-4 mb-3">
                      <label className="text_dark mb-2">Country</label>
                      <select
                        className="formcontrol categoriesajax select2-hidden-accessible"
                        name="country"
                        id="categorycontact"
                        required
                      >
                        <option value="">Select</option>
                        {Object.keys(countries).map((cont, index) => {
                          return (
                            <option key={index} value={cont}>
                              {countries[cont]}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    {/* <div className="col-lg-4 mb-3">
                                            <label className="text_dark mb-2">Equipment</label>
                                            <select className="formcontrol categoriesajax select2-hidden-accessible" name="category" id="categorycontact" required >
                                                <option value="">All</option>
                                                {brandsAndCatList && brandsAndCatList.categories.map((cat, index) => {
                                                    return (
                                                        <option key={index} value={cat.name} data-select2-id="select2-data-592-omoj">{cat.name}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                        <div className="col-lg-4 mb-3">
                                            <label className="text_dark mb-2">Brands</label>
                                            <select className="formcontrol categoriesajax select2-hidden-accessible" name="brand" id="categorycontact" required >
                                                <option value="">All</option>
                                                {brandsAndCatList && brandsAndCatList.brands.map((cat, index) => {
                                                    return (
                                                        <option key={index} value={cat.name} data-select2-id="select2-data-592-omoj">{cat.name}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>

                                        <div className="col-lg-4 mb-3">
                                            <label className="text_dark mb-2">Model</label>
                                            <input type="text" name="model" id="model" placeholder="Type Model" className="formcontrol" required="required" />
                                        </div>
                                         */}
                    <div className="col-lg-4 mb-3">
                      <label className="text_dark mb-2">Subject</label>
                      <input
                        type="text"
                        name="subject"
                        id="subject"
                        placeholder="Type Subject"
                        className="formcontrol"
                        required="required"
                      />
                    </div>
                    <div className="col-lg-12 mb-3">
                      <label className="text_dark mb-2">Message</label>
                      <textarea
                        name="message"
                        id="description"
                        placeholder="Type your message here"
                        className="formcontrol"
                        required="required"
                        defaultValue={""}
                      />
                    </div>
                    <div className="col-lg-12 mb-3 mt-lg-1">
                      {!showButton ? (
                        <button className="btn_light mt-0" type="submit">
                          <i className="bi bi-send-fill"></i> Send Message
                        </button>
                      ) : (
                        // <button type="submit">
                        <div className="spinner-border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                        // </button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          {/* contact data  */}
          <div className="contact-bottom aos-init aos-animate mt-5">
            <div className="row contact-sec bg_gray">
              <div className="col-lg-6">
                <div
                  className="sb_contact_list aos-init aos-animate"
                  data-aos="fade-up"
                >
                  <h5 style={{ textTransform: "uppercase" }}>
                    Purchasing department
                  </h5>
                  <h6>Mike Eddie </h6>
                  {/* <p>Seals Manager</p> */}
                  <ul>
                    <li className="border-none">
                      <i className="bi bi-telephone-fill" />
                      <a href="tel:+16575494177">657-549-4177</a>
                      <a href="tel:+16572367399">657-236-7399</a>
                    </li>
                    <li className="border-none">
                      <i className="bi bi-envelope" />
                      <a href="mailto:sales4@gcesequipment.com">
                        <span className="__cf_email__  oemail">
                          Sales4@gcesequipment.com
                        </span>
                      </a>
                      <a href="mailto:sales6@gcesequipment.com">
                        Sales6@gcesequipment.com
                      </a>
                    </li>
                  </ul>
                  <h6>George Gamil</h6>
                  {/* <p>Logistics Manager</p> */}
                  <ul>
                    <li className="border-none">
                      <i className="bi bi-telephone-fill" />
                      <a href="tel:+16575494191">657-549-4191</a>
                      <a href="tel:+16572748033">657-274-8033</a>
                    </li>
                    <li className="border-none">
                      <i className="bi bi-envelope" />
                      <a href="mailto:info@gcesequipment.com">
                        <span className="__cf_email__  katemail">
                          info@gcesequipment.com
                        </span>
                      </a>
                    </li>
                  </ul>
                  <h6>Mike Philips</h6>
                  {/* <p>Seals Manager</p> */}
                  <ul>
                    <li className="border-none">
                      <i className="bi bi-telephone-fill" />
                      <a href="tel:+16574562107">657-456-2107</a>
                    </li>
                    <li className="border-none">
                      <i className="bi bi-envelope" />
                      <a href="mailto:sales2@gcesequipment.com">
                        <span className="__cf_email__  kemail">
                          Sales2@gcesequipment.com
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
                {/* <div className="footer-loc-elem sb_contact_list">
                                    <h1 />
                                    <ul>
                                        <li><i className="bi bi-geo-alt-fill" /> 1400 Broadfield Blvd, <span className="d-lg-block">Houston, TX 77084, USA.</span></li>
                                        <li><i className="fa fa-fax" />
                                            <a href="tel:+12819344003" className="fax-triggered">+1-281-934-4003 (FAX)</a>
                                        </li>
                                    </ul>
                                </div> */}
              </div>
              <div className="col-lg-6">
                <div
                  className="sb_contact_list aos-init aos-animate"
                  data-aos="fade-up"
                >
                  <h5 style={{ visibility: "hidden" }}>
                    LOGISTICS (TRUCKING/SHIPPING)
                  </h5>
                  <h6>Joe Harry </h6>
                  {/* <p>Seals Manager</p> */}
                  <ul>
                    <li className="border-none">
                      <i className="bi bi-telephone-fill" />
                      <a href="tel:+16573476605">657-347-6605</a>
                    </li>
                    <li className="border-none">
                      <i className="bi bi-envelope" />
                      <a href="mailto:info@gcesequipment.com">
                        <span className="__cf_email__  kemail">
                          info@gcesequipment.com
                        </span>
                      </a>
                      <a href="mailto:dealers@gcesequipment.com">
                        <span className="__cf_email__  kemail">
                          Dealers@gcesequipment.com
                        </span>
                      </a>
                    </li>
                  </ul>

                  <h6> Tony EbraHem </h6>
                  {/* <p>Logistics Assistant</p> */}
                  <ul>
                    <li className="border-none">
                      <i className="bi bi-telephone-fill" />
                      <a href="tel:+16574562544">657-456-2544</a>
                    </li>
                    <li className="border-none">
                      <i className="bi bi-envelope" />
                      <a href="mailto:sales7@gcesequipment.com">
                        <span className="__cf_email__  hemail">
                          Sales7@gcesequipment.com
                        </span>
                      </a>
                    </li>
                  </ul>
                  <h6>Andrew Romany</h6>
                  {/* <p>Logistics Assistant</p> */}
                  <ul>
                    <li className="border-none">
                      <i className="bi bi-telephone-fill" />
                      <a href="tel:+16572367707">657-236-7707</a>
                    </li>
                    <li className="border-none">
                      <i className="bi bi-envelope" />
                      <a href="mailto:sales1@gcesequipment.com">
                        <span className="__cf_email__  hemail">
                          Sales1@gcesequipment.com
                        </span>
                      </a>
                    </li>
                  </ul>
                  <h6>Albert Yousry</h6>
                  {/* <p>Logistics Assistant</p> */}
                  <ul>
                    <li className="border-none">
                      <i className="bi bi-telephone-fill" />
                      <a href="tel:+16572367504">657-236-7504</a>
                    </li>
                    <li className="border-none">
                      <i className="bi bi-envelope" />
                      <a href="mailto:sales2@gcesequipment.com">
                        <span className="__cf_email__  hemail">
                          Sales2@gcesequipment.com
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <hr className="my-4" />
              <div className="col-12 ">
                <div className="sb_contact_list aos-init aos-animate">
                  <h5 style={{ textTransform: "uppercase" }}>
                    Shipping Department
                  </h5>
                  <h6>Kareem Etman </h6>
                  <ul>
                    <li className="border-none">
                      <i className="bi bi-telephone-fill" />
                      <a href="tel:+16574561039">657-456-1039</a>
                    </li>
                    <li className="border-none">
                      <i className="bi bi-envelope" />
                      <a href="mailto:shipping@gcesequipment.com">
                        <span className="__cf_email__  aemail">
                          shipping@gcesequipment.com
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <hr className="my-4" />
              <div className="col-12 ">
                <div className="sb_contact_list aos-init aos-animate">
                  <h5 style={{ textTransform: "uppercase" }}>
                    Parts department
                  </h5>
                  <h6>Albair Yousry </h6>
                  <ul>
                    <li className="border-none">
                      <i className="bi bi-telephone-fill" />
                      <a href="tel:+17414081444">741-408-1444</a>
                    </li>
                    <li className="border-none">
                      <i className="bi bi-envelope" />
                      <a href="mailto:parts@gcesequipment.com">
                        <span className="__cf_email__  aemail">
                          Parts@gcesequipment.com{" "}
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <hr className="my-4" />
              <div className="col-12 ">
                <div className="sb_contact_list aos-init aos-animate">
                  <h5 style={{ textTransform: "uppercase" }}>
                    Auction Department
                  </h5>
                  <h6>Poula / Alex</h6>
                  <ul>
                    {/* <li className='border-none'><i className="bi bi-telephone-fill" />
                                            <a href="tel:+17414081444">741-408-1444</a>
                                        </li> */}
                    <li className="border-none">
                      <i className="bi bi-envelope" />
                      <a href="mailto:auctions@gcesequipment.com">
                        <span className="__cf_email__  aemail">
                          Auctions@gcesequipment.com
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <hr className="my-4" />
              <div className="col-12 ">
                <div className="sb_contact_list aos-init aos-animate">
                  <h5 style={{ textTransform: "uppercase" }}>
                    Sales Department
                  </h5>
                  <h6>Ferry Beshara </h6>
                  <ul>
                    <li className="border-none">
                      <i className="bi bi-telephone-fill" />
                      <a href="tel:+16572527225">657-252-7225</a>
                    </li>
                    {/* <li className='border-none'><i className="bi bi-envelope" />
                                            <a href="mailto:parts@gcesequipment.com "><span className="__cf_email__  aemail">Parts@gcesequipment.com </span></a>
                                        </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default ContactUs;
