import React from 'react';
import SoldEquipment from '../../Components/Sold Equipment/soldEquipment';

const SoldTelehandler = () => {
    return (
        <div>
            <SoldEquipment cat="Telehandler" name="telehandler"/>
        </div>
    );
}

export default SoldTelehandler;
