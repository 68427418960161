import React, { useEffect } from 'react';
import 'animate.css';
import './banner.css';

const Banner = () => {

    useEffect(() => {
        const video = document.getElementById('banner_vedio');
        video.play();
    }, []);
    return (
        <section className='home_banner'>
            <video muted loop id="banner_vedio" style={{ display: 'inline' }}>
                <source src="./videos/hover 4.mp4" type="video/mp4" />
            </video>
            <div className='container'>
                <div className="row">
                    <div className='col-lg-8 align-self-center'>
                        
                        <div id='heroCarousel' className='home_banner_slider carousel carousel-fade slide' data-ride="carousel">
                            <ol className="carousel-indicators d-none">
                                <li data-target="#heroCarousel" data-slide-to={0}  />
                                <li data-target="#heroCarousel" data-slide-to={1} />
                                <li data-target="#heroCarousel" data-slide-to={2} />
                                <li data-target="#heroCarousel" data-slide-to={3} />
                            </ol>
                            <div id="carouselExampleAutoplaying" className="carousel slide" data-bs-ride="carousel">
                                <div className="carousel-inner" role='listbox'>
                                   
                                    <div className='carousel-item active ' style={{}}>
                                        <div className='carousel-container'>
                                            <h2 className='lg_text animate__animated animate__fadeInRight '>
                                                YOUR PREMIUM
                                                <span className="d-block">MACHINERY </span>
                                                <span className="d-block">PROVIDER </span>
                                            </h2>
                                            <div className='divider  animate__animated animate__fadeInRight animate__delay-0.5s'></div>
                                            <p className='sm_text animate__animated animate__fadeInRight animate__delay-1s '>
                                                Considering about your next machine ?
                                                drop us a call, we do the rest.
                                            </p>
                                            <a href="/" className='btn_banner mr-2 animate__animated animate__fadeInUp animate__delay-2s'>
                                                <i className="bi bi-receipt-cutoff main-color fs-4"></i>
                                                &nbsp; View Inventory
                                            </a>
                                            <a href="/contact-us" className='btn_banner animate__animated animate__fadeInUp animate__delay-2s justify-items-center justify-content-center '>
                                                <i className="bi bi-chat-left-quote main-color fs-4 "></i>
                                                &nbsp; Contact Us
                                            </a>
                                        </div>
                                    </div>
                                    
                                    <div className='carousel-item ' style={{}}>
                                        <div className='carousel-container'>
                                            <h2 className='lg_text animate__animated animate__fadeInRight '>
                                                GREAT MACHINES
                                                <span className="d-block">DO GREAT</span>
                                                <span className="d-block">WORK</span>
                                            </h2>
                                            <div className='divider  animate__animated animate__fadeInRight animate__delay-0.5s'></div>
                                            <p className='sm_text animate__animated animate__fadeInRight animate__delay-1s '>
                                                Explore the finest machinery with GCES
                                                Used equipment and attachments !

                                            </p>
                                            <a href="/" className='btn_banner mr-2 animate__animated animate__fadeInUp animate__delay-2s'>
                                                <i className="bi bi-receipt-cutoff main-color fs-4"></i>
                                                &nbsp; View Inventory
                                            </a>
                                            <a href="/contact-us" className='btn_banner animate__animated animate__fadeInUp animate__delay-2s justify-items-center justify-content-center '>
                                                <i className="bi bi-chat-left-quote main-color fs-4 "></i>
                                                &nbsp; Contact Us
                                            </a>
                                        </div>
                                    </div>
                                    
                                    <div className='carousel-item ' style={{}}>
                                        <div className='carousel-container'>
                                            <h2 className='lg_text animate__animated animate__fadeInRight '>
                                                EXPLORE VARITY OF
                                                <span className="d-block">EQUIPMENT & ATTACHMENT </span>
                                                <span className="d-block">WITH GCES </span>
                                            </h2>
                                            <div className='divider  animate__animated animate__fadeInRight animate__delay-0.5s'></div>
                                            <p className='sm_text animate__animated animate__fadeInRight animate__delay-1s '>
                                                Equipment from all manufactures and all categories
                                                Add-ons brand new attachment that complete your work!
                                            </p>
                                            <a href="/" className='btn_banner mr-2 animate__animated animate__fadeInUp animate__delay-2s'>
                                                <i className="bi bi-receipt-cutoff main-color fs-4"></i>
                                                &nbsp; View Inventory
                                            </a>
                                            <a href="/contact-us" className='btn_banner animate__animated animate__fadeInUp animate__delay-2s justify-items-center justify-content-center '>
                                                <i className="bi bi-chat-left-quote main-color fs-4 "></i>
                                                &nbsp; Contact Us
                                            </a>
                                        </div>
                                    </div>
                                    
                                    <div className='carousel-item '  style={{}}>
                                        <div className='carousel-container'>
                                            <h2 className='lg_text animate__animated animate__fadeInRight '>
                                                WE’RE HERE TO HELP
                                                <span className="d-block">YOU FIND YOUR NEXT </span>
                                                <span className="d-block">MACHINERY </span>
                                            </h2>
                                            <div className='divider  animate__animated animate__fadeInRight animate__delay-0.5s'></div>
                                            <p className='sm_text animate__animated animate__fadeInRight animate__delay-1s '>
                                                Our representatives works hard to find your inquiry ASAP, we believe that your work must keep going.
                                            </p>
                                            <a href="/" className='btn_banner mr-2 animate__animated animate__fadeInUp animate__delay-2s'>
                                                <i className="bi bi-receipt-cutoff main-color fs-4"></i>
                                                &nbsp; View Inventory
                                            </a>
                                            <a href="/contact-us" className='btn_banner animate__animated animate__fadeInUp animate__delay-2s justify-items-center justify-content-center '>
                                                <i className="bi bi-chat-left-quote main-color fs-4 "></i>
                                                &nbsp; Contact Us
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                {/* <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev">
                                    <span className="carousel-control-prev-icon" aria-hidden="true" />
                                    <span className="visually-hidden">Previous</span>
                                </button>
                                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next">
                                    <span className="carousel-control-next-icon" aria-hidden="true" />
                                    <span className="visually-hidden">Next</span>
                                </button> */}
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Banner;
