import React from 'react';

const PrivacyPolicy = () => {
    return (
        <main>
            <section className='single_equipment py-lg-5 py-4'>
                <div className="container">
                    <div className='navigation_border mb-lg-4 mb-3 aos-init aos-animate'>
                        <div className="row">
                            <div className='col-md-12 text-md-right text-center pb-lg-3 d-none d-sm-block'>
                                <ul className="sort_breadcrums">
                                    <li><a href="/">Home</a></li>
                                    <li>PRIVACY POLICY</li>
                                </ul>


                            </div>
                        </div>
                    </div>
                    <div className='content_area'>
                        <h3 className='mini_border_title pt-2 aos-init aos-animate'>
                            Privacy Policy
                        </h3>
                        <p className='aos-init aos-animate'>
                            Find all the relevant information regarding why we collect your data and how it is processed in the following privacy statement. The statement includes all the details about where and why your data is stored. You can also find other information in the statement concerning your rights about how your personal data is handled. The statement is subject to changes at any time, and it is advised for you to consult it regularly.
                        </p>
                        <h3 className='mini_border_title pt-2 aos-init aos-animate'>
                            MY Equipment
                        </h3>
                        <p className='aos-init aos-animate'>
                            This is the privacy statement of MY Equipment LLC, located in Houston, Texas. MY Equipment is an international trading company dealing in heavy machinery. There are certain circumstances where your information may be collected by the company. Therefore, it stands to reason that you should know how your information is handled so you can also provide an input on the matter. The purpose of this statement is to make this knowledge public, and if there is anything that makes you uncomfortable in this policy regarding how your data is handled, feel free to reach out and contact us.
                        </p>
                        <h3 className='mini_border_title pt-2 aos-init aos-animate'>
                            Purpose for Storing Data
                        </h3>
                        <p className='aos-init aos-animate'>
                            MY Equipment stores your data for a number of different reasons.
                        </p>
                        <h4 className='fw-medium'>Distributing Newsletters</h4>
                        <p className='aos-init aos-animate'>
                            MY Equipment collects your name and email through the website in order to send newsletters to potential clients and customers. These newsletters are only sent to interested parties to bring their attention to any new products or deals offered.
                        </p>
                        <h4 className='fw-medium'>Contact Us</h4>
                        <p className='aos-init aos-animate'>
                            As soon as you contact us through the website’s portal, your data will be collected from the website. The information that you provide on the contact form will be used to make you offers.
                        </p>
                        <h4 className='fw-medium'>Analytics</h4>
                        <p className='aos-init aos-animate'>
                            The MY Equipment website collects data from the website to improve its performance. This data is collected by tools like Google Analytics and does not collect any personal details. The data collected includes details like the amount of time you spent on the website and the pages you frequented. All the data that is collected is done so with your explicit consent, and no agreements or contacts are made without it.
                        </p>
                        <h4 className='fw-medium'>Mailchimp</h4>
                        <p className='aos-init aos-animate'>
                            MY Equipment uses Mailchimp to send newsletters. Once you sign up for our newsletters on our website, Mailchimp automatically collects your information (name and email address) and stores it in a list.
                        </p>

                        <h3 className='mini_border_title pt-2 aos-init aos-animate'>
                            Time Duration of Data Storage
                        </h3>
                        <p className='aos-init aos-animate'>
                            MY Equipment collects and stores data for a certain amount of time, but it is never stored for longer than is necessary to execute the aforementioned activities unless, of course, it is mandatory by the law to store the data for a longer time period.
                        </p>
                        <h4 className='fw-medium'>Newsletters</h4>
                        <p className='aos-init aos-animate'>
                            The data that is collected by Mailchimp to distribute newsletters is stored for an indefinite amount of time. However, you can choose to unsubscribe from the newsletter at any point in time.
                        </p>
                        <h4 className='fw-medium'>Analytics</h4>
                        <p className='aos-init aos-animate'>
                            The data that Google Analytics collects does not include any personal information and is not linked to your name, email, or any such things. This data is stored for an indefinite period as well.
                        </p>

                        <h3 className='mini_border_title pt-2 aos-init aos-animate'>
                            Security
                        </h3>
                        <p className='aos-init aos-animate'>
                            No physical copies are made of your personal data. All the data collected is handled by the software companies and systems mentioned above. All the data collected by MY Equipment can only be accessed by the software mentioned and is protected by strong passwords and two step verifications where possible. The number of devices that can access your data is also limited
                        </p>
                        <h3 className='mini_border_title pt-2 aos-init aos-animate'>
                            Rights
                        </h3>
                        <h4 className='fw-medium'>Inspection</h4>
                        <p className='aos-init aos-animate'>
                            You have a right to review your personal data that is collected and stored by MY Equipment at any time. You can request for it by sending an email or contacting us via phone. Once you have made the request, you will soon receive an overview of the data we have collected and stored.
                        </p>
                        <h4 className='fw-medium'>Rectification</h4>
                        <p className='aos-init aos-animate'>
                            If there are there any errors in the details or they have changed recently, you are well within your rights to have them rectified by MY Equipment.


                        </p>
                        <h4 className='fw-medium'>Deletion</h4>
                        <p className='aos-init aos-animate'>
                            If you are no longer interested in having your details stored on the MY Equipment server, you have the right to delete all of your personal data from the website.


                        </p>
                        <h4 className='fw-medium'>Cease Use of Data </h4>
                        <p className='aos-init aos-animate'>
                            If you do not want MY Equipment to use the personal data on you that we have stored, you are well within your rights to cease all use of your personal details. You can send an email to our support team requesting to remove your personal details along with a copy of your ID.


                        </p>

                        <h3 className='mini_border_title pt-2 aos-init aos-animate'>
                            Obligations
                        </h3>
                        <p className='aos-init aos-animate'>
                            The only purpose behind MY Equipment handling personal data is commercial interest. MY Equipment’s only interest remains with advertising its products and services to interested clientele. The data that is collected by my equipment will never be sold to any third parties.
                        </p>
                        <p className='aos-init aos-animate'>
                            The information that you are asked to provide is the bare minimum and only what is absolutely necessary for selling products and services, such as your email address to send you newsletters. If for some reason it is necessary to disclose your data to a third party, we will ask for your consent first. We will do our best to protect your privacy. If you have any other questions, feel free to reach out to us.

                        </p>

                    </div>
                </div>

            </section>

        </main>
    );
}

export default PrivacyPolicy;
