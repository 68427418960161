import React from 'react';
import SoldEquipment from '../../Components/Sold Equipment/soldEquipment';

const SoldWheelLoaders = () => {
    return (
        <div>
            <SoldEquipment cat="Wheel Loaders" name="wheel-loaders" />
        </div>
    );
}

export default SoldWheelLoaders;
