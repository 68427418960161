import React from 'react';
import './videos.css';

const Videos = () => {
    return (
        <main>
            <section className='brands_logos py-5'>
                <div className='container aos-init aos-animate'>
                    <h3 className='border_title txt_dark'>
                    Youtube Videos 

                    </h3>

                </div>

            </section>
            
        </main>
    );
}

export default Videos;
