import React, { useEffect } from "react";
import "./page404.css";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import ListCategoriesBrandsSlice from "../../store/action/listCategories-BrandsSlice";
import { useNavigate } from "react-router-dom";

const Page404 = () => {
  const categoriesBrands = useSelector((state) => state.categoriesBrands);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // get all categories and Brands when hover of category
  const gelAllBrandsAndCat = async () => {
    if (!categoriesBrands) {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/products/categories-brands`
      );
      dispatch(ListCategoriesBrandsSlice(res.data));
      // console.log(res.data);
    } else {
      console.log("dont have categoriesBrands");
    }
  };

  useEffect(() => {
    gelAllBrandsAndCat();
  }, [dispatch]);
  return (
    <main>
      <section className="single_equipment">
        <div className="content_area">
          <div id="notfound" style={{ minHeight: "800px" }}>
            <div className="notfound" style={{ margin: "0px" }}>
              <div
                className="notfound-404"
                style={{
                  marginTop: "80px",
                  marginBottom: "40px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img src="https://www.my-equipment.com/404.png" alt="404" className="img-404" />
              </div>
              <section
                className="tag_section "
                style={{ textAlign: "initial" }}
              >
                <div className="container aos-init aos-animate">
                  <h3 className="border_title txt_dark whatlooking">
                    WHAT YOU WERE LOOKING FOR ?
                  </h3>
                  <ul
                    className="mt-4 ml-lg-5"
                    style={{ marginTop: "0px !important" }}
                  >
                    {categoriesBrands &&
                      categoriesBrands.categories.map((cat, index) => {
                        return (
                          <li key={index}>
                            <a
                              href={`${cat.name
                                .toLowerCase()
                                .replace(/\s+/g, "-")}`}
                              className="links-cat"
                            >
                              {cat.name}
                            </a>
                          </li>
                        );
                      })}

                    {/* <li>
                      <a href="/motor-graders" className="links-cat">
                        MOTOR GRADER
                      </a>
                    </li>
                    <li>
                      <a href="/track-excavators" className="links-cat">
                        TRACK EXCAVATOR
                      </a>
                    </li>
                    <li>
                      <a href="/wheel-excavators" className="links-cat">
                        WHEEL EXCAVATOR
                      </a>
                    </li>
                    <li>
                      <a href="/crawler-dozers" className="links-cat">
                        CRAWLER DOZER
                      </a>
                    </li>
                    <li>
                      <a href="/backhoe-loaders" className="links-cat">
                        BACKHOE LOADERS
                      </a>
                    </li>
                    <li>
                      <a href="/forklift" className="links-cat">
                        FORKLIFTS
                      </a>
                    </li>
                    <li>
                      <a href="/vibratory-rollers" className="links-cat">
                        VIBRATORY ROLLERS
                      </a>
                    </li>
                    <li>
                      <a href="/cranes" className="links-cat">
                        CRANES
                      </a>
                    </li>
                    <li>
                      <a href="/agriculture-tractors" className="links-cat">
                        AGRICULTURE TRACTORS
                      </a>
                    </li>
                    <li>
                      <a href="/parts-attachments" className="links-cat">
                        PARTS &amp; ATTACHMENTS
                      </a>
                    </li> */}
                  </ul>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Page404;
