import React, { useEffect, useState } from "react";
import "./sideBar.css";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import ListCategoriesBrandsSlice from "../../store/action/listCategories-BrandsSlice";
import { handleAlert } from "../../functions/handleAlert";

export default function SideBar() {
  const brandsAndCats = useSelector((state) => state.categoriesBrands);
  const [showButton, setShowButton] = useState(false);
  const dispatch = useDispatch();

  const getBrandsAndCats = async () => {
    if (!brandsAndCats) {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/products/categories-brands`
      );
      dispatch(ListCategoriesBrandsSlice(res.data));
    }
  };
  // all country
  var countries = {
    afghanistan: "Afghanistan",
    albania: "Albania",
    algeria: "Algeria",
    andorra: "Andorra",
    angola: "Angola",
    antigua_and_barbuda: "Antigua and Barbuda",
    argentina: "Argentina",
    armenia: "Armenia",
    australia: "Australia",
    austria: "Austria",
    azerbaijan: "Azerbaijan",
    bahamas: "Bahamas",
    bahrain: "Bahrain",
    bangladesh: "Bangladesh",
    barbados: "Barbados",
    belarus: "Belarus",
    belgium: "Belgium",
    belize: "Belize",
    benin: "Benin",
    bhutan: "Bhutan",
    bolivia: "Bolivia",
    bosnia_and_herzegovina: "Bosnia and Herzegovina",
    botswana: "Botswana",
    brazil: "Brazil",
    brunei: "Brunei",
    bulgaria: "Bulgaria",
    burkina_faso: "Burkina Faso",
    burundi: "Burundi",
    cabo_verde: "Cabo Verde",
    cambodia: "Cambodia",
    cameroon: "Cameroon",
    canada: "Canada",
    central_african_republic: "Central African Republic",
    chad: "Chad",
    chile: "Chile",
    china: "China",
    colombia: "Colombia",
    comoros: "Comoros",
    congo_democratic_republic_of_the: "Congo, Democratic Republic of the",
    congo_republic_of_the: "Congo, Republic of the",
    costa_rica: "Costa Rica",
    croatia: "Croatia",
    cuba: "Cuba",
    cyprus: "Cyprus",
    czech_republic: "Czech Republic",
    denmark: "Denmark",
    djibouti: "Djibouti",
    dominica: "Dominica",
    dominican_republic: "Dominican Republic",
    east_timor: "East Timor",
    ecuador: "Ecuador",
    egypt: "Egypt",
    el_salvador: "El Salvador",
    equatorial_guinea: "Equatorial Guinea",
    eritrea: "Eritrea",
    estonia: "Estonia",
    eswatini: "Eswatini",
    ethiopia: "Ethiopia",
    fiji: "Fiji",
    finland: "Finland",
    france: "France",
    gabon: "Gabon",
    gambia: "Gambia",
    georgia: "Georgia",
    germany: "Germany",
    ghana: "Ghana",
    greece: "Greece",
    grenada: "Grenada",
    guatemala: "Guatemala",
    guinea: "Guinea",
    guinea_bissau: "Guinea-Bissau",
    guyana: "Guyana",
    haiti: "Haiti",
    honduras: "Honduras",
    hungary: "Hungary",
    iceland: "Iceland",
    india: "India",
    indonesia: "Indonesia",
    iran: "Iran",
    iraq: "Iraq",
    ireland: "Ireland",
    israel: "Israel",
    italy: "Italy",
    ivory_coast: "Ivory Coast",
    jamaica: "Jamaica",
    japan: "Japan",
    jordan: "Jordan",
    kazakhstan: "Kazakhstan",
    kenya: "Kenya",
    kiribati: "Kiribati",
    korea_north: "Korea, North",
    korea_south: "Korea, South",
    kosovo: "Kosovo",
    kuwait: "Kuwait",
    kyrgyzstan: "Kyrgyzstan",
    laos: "Laos",
    latvia: "Latvia",
    lebanon: "Lebanon",
    lesotho: "Lesotho",
    liberia: "Liberia",
    libya: "Libya",
    liechtenstein: "Liechtenstein",
    lithuania: "Lithuania",
    luxembourg: "Luxembourg",
    madagascar: "Madagascar",
    malawi: "Malawi",
    malaysia: "Malaysia",
    maldives: "Maldives",
    mali: "Mali",
    malta: "Malta",
    marshall_islands: "Marshall Islands",
    mauritania: "Mauritania",
    mauritius: "Mauritius",
    mexico: "Mexico",
    micronesia: "Micronesia",
    moldova: "Moldova",
    monaco: "Monaco",
    mongolia: "Mongolia",
    montenegro: "Montenegro",
    morocco: "Morocco",
    mozambique: "Mozambique",
    myanmar: "Myanmar",
    namibia: "Namibia",
    nauru: "Nauru",
    nepal: "Nepal",
    netherlands: "Netherlands",
    new_zealand: "New Zealand",
    nicaragua: "Nicaragua",
    niger: "Niger",
    nigeria: "Nigeria",
    north_macedonia: "North Macedonia",
    norway: "Norway",
    oman: "Oman",
    pakistan: "Pakistan",
    palau: "Palau",
    panama: "Panama",
    papua_new_guinea: "Papua New Guinea",
    paraguay: "Paraguay",
    peru: "Peru",
    philippines: "Philippines",
    poland: "Poland",
    portugal: "Portugal",
    qatar: "Qatar",
    romania: "Romania",
    russia: "Russia",
    rwanda: "Rwanda",
    saint_kitts_and_nevis: "Saint Kitts and Nevis",
    saint_lucia: "Saint Lucia",
    saint_vincent_and_the_grenadines: "Saint Vincent and the Grenadines",
    samoa: "Samoa",
    san_marino: "San Marino",
    sao_tome_and_principe: "Sao Tome and Principe",
    saudi_arabia: "Saudi Arabia",
    senegal: "Senegal",
    serbia: "Serbia",
    seychelles: "Seychelles",
    sierra_leone: "Sierra Leone",
    singapore: "Singapore",
    slovakia: "Slovakia",
    slovenia: "Slovenia",
    solomon_islands: "Solomon Islands",
    somalia: "Somalia",
    south_africa: "South Africa",
    south_sudan: "South Sudan",
    spain: "Spain",
    sri_lanka: "Sri Lanka",
    sudan: "Sudan",
    suriname: "Suriname",
    sweden: "Sweden",
    switzerland: "Switzerland",
    syria: "Syria",
    taiwan: "Taiwan",
    tajikistan: "Tajikistan",
    tanzania: "Tanzania",
    thailand: "Thailand",
    togo: "Togo",
    tonga: "Tonga",
    trinidad_and_tobago: "Trinidad and Tobago",
    tunisia: "Tunisia",
    turkey: "Turkey",
    turkmenistan: "Turkmenistan",
    tuvalu: "Tuvalu",
    uganda: "Uganda",
    ukraine: "Ukraine",
    united_arab_emirates: "United Arab Emirates",
    united_kingdom: "United Kingdom",
    united_states_of_america: "United States of America",
    uruguay: "Uruguay",
    uzbekistan: "Uzbekistan",
    vanuatu: "Vanuatu",
    vatican_city: "Vatican City",
    venezuela: "Venezuela",
    vietnam: "Vietnam",
    yemen: "Yemen",
    zambia: "Zambia",
    zimbabwe: "Zimbabwe",
  };

  const sendRequest = async (event) => {
    event.preventDefault();
    setShowButton(true);
    let name = event.target.name.value;
    let email = event.target.email.value;
    let phone = event.target.phone.value;
    let companyName = event.target.companyName.value;
    let country = event.target.country.value;
    let cat = event.target.category.value;
    let model = event.target.model.value;
    // let subject = event.target.subject.value;
    let message = event.target.message.value;
    let brand = event.target.brand.value;
    // let price = parseInt(event.target.price.value);

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/contact-us`,
        {
          name: name,
          email: email,
          phone: phone,
          companyName: companyName,
          country: country,
          equipment: cat,
          message: message,
          model: model,
          subject: "",
          brand: brand,
          // "year": year
        }
      );
      setShowButton(false);
      handleAlert(res.data.message, "success");
      //   console.log(res.data);
    } catch (error) {
      handleAlert(error.response.data.message, "error");
    }
  };
  useEffect(() => {
    // getBrandsAndCats();
  }, [dispatch]);
  return (
    <>
      <div className="an-sidebar-cta d-none d-md-block">
        <div className="an-cta-phone">
          <button className="small-btn">
            <a href="tel:+16573476660">
              <img
                src="https://www.my-equipment.com/newsite-v2/assets/img/cta-1.png"
                alt=""
              />
            </a>
          </button>
        </div>
        {/* <div className='an-cta-phone'>
                <button className='small-btn'>
                    <img src="https://www.my-equipment.com/newsite-v2/assets/img/cta-2.png" alt="" />
                </button>
            </div> */}
        <div className="an-cta-form " id="show-form">
          <button
            onClick={() => {
              let form = document.getElementById("show-form");
              form.classList.toggle("active");
            }}
          >
            Request A Custom Content
            <img
              src="https://www.my-equipment.com/newsite-v2/assets/img/cta-3.png"
              alt=""
            />
          </button>
          <form className="cta-form " onSubmit={sendRequest}>
            <div className="row">
              <div className="col-lg-6">
                <label>
                  NAME <span className="an-req">*</span>{" "}
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="user-name"
                  name="name"
                  placeholder="Your Name"
                  required="required"
                />
              </div>
              <div className="col-lg-6">
                <label>
                  Email <span className="an-req">*</span>{" "}
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="user-email"
                  name="email"
                  placeholder="Your Email"
                  required="required"
                />
              </div>
              <div className="col-lg-6">
                <label>EQUIPMENT </label>
                <select
                  className="an-search-detail-item quote_category categoriesajax form-select select2-hidden-accessible"
                  name="category"
                  id="category"
                  required="required"
                  tabIndex="-1"
                  aria-hidden="true"
                  data-select2-id="category"
                >
                  <option value="" selected>
                    All
                  </option>
                  {brandsAndCats &&
                    brandsAndCats.categories.map((cat, _i) => {
                      return (
                        <option key={_i} value={cat.name}>
                          {cat.name}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div className="col-lg-6">
                <label>Brand </label>

                <select
                  name="brand"
                  className="an-search-detail-item form-select brandselect2 select2-hidden-accessible"
                  id="brand"
                  required="required"
                  tabIndex="-1"
                  aria-hidden="true"
                  data-select2-id="brand"
                >
                  <option value="" selected="selected">
                    All
                  </option>
                  {brandsAndCats &&
                    brandsAndCats.brands.map((brand, _i) => {
                      return (
                        <option key={_i} value={brand.name}>
                          {brand.name}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div className="col-lg-6">
                <label>Model </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Type Model"
                  name="model"
                  id="model"
                  required="required"
                />
              </div>
              <div className="col-lg-6">
                <label>Phone Number </label>
                <input
                  type="tel"
                  className="form-control"
                  name="phone"
                  id="contact_nbr"
                  placeholder="Your Number"
                  required="required"
                />
              </div>
              <div className="col-lg-6">
                <label>Company Name </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Type Company Name"
                  name="companyName"
                  id="model"
                  required="required"
                />
              </div>
              <div className="col-lg-6">
                <label>Country</label>
                <select
                  className="an-search-detail-item quote_category categoriesajax form-select select2-hidden-accessible"
                  name="country"
                  id="categorycontact"
                  required
                >
                  <option value="" selected>
                    Select
                  </option>
                  {Object.keys(countries).map((cont, index) => {
                    return (
                      <option key={index} value={cont}>
                        {countries[cont]}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="col-lg-12">
                <label>MESSAGE </label>
                <textarea
                  className="form-control"
                  name="message"
                  id="description"
                  placeholder="Type your message here"
                  required="required"
                ></textarea>
              </div>
              <div className="col-lg-6">
                <div className="contact_form_field contactcaptcha contact_recap captcha_div">
                  <div
                    id="stickyrequestaquote"
                    style={{
                      transform: "scale(0.55)",
                      transformOrigin: "0px 0px",
                    }}
                  ></div>
                  <span id="captcha_contact_form" required="required"></span>
                </div>
              </div>
              <div className="col-lg-6">
                {!showButton ? (
                  <button type="submit" className="an-theme-btn dark">
                    {" "}
                    <img
                      src="https://www.my-equipment.com/newsite-v2/assets/img/paper-plane.png"
                      alt=""
                    />
                    Send Message
                  </button>
                ) : (
                  <button type="submit">
                    <div
                      className="spinner-border an-theme-btn dark"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
      <button className="an-chat-button">
        {/* <img src="" alt="" /> */}
        <a
          href="https://api.whatsapp.com/send?phone=+16573476660&amp;text=Hello! GCES, i want to chat with you regarding a machine. Reply me asap."
          target="_blank"
        >
          <img
            src="https://www.my-equipment.com/newsite-v2/assets/img/chat-btn.png"
            alt=""
          />
        </a>
      </button>
    </>
  );
}
