import React from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

class MyGallery extends React.Component {

  render() {
    // const images = this.props.product.images?.map(img => ({
    //   original: `${process.env.REACT_APP_SERVER_URL}/${img}`,
    //   thumbnail: `${process.env.REACT_APP_SERVER_URL}/${img}`,
    // }));
    

    return (
      <section className='home_banner'>
        <video muted loop id="banner_vedio" style={{ display: 'inline' }}>
          <source src="https://www.my-equipment.com/newsite/images/banner-video-compress.mp4?a=8" type="video/mp4" />
        </video>
        <div className='container'>
          <div className="row">
            <div className='col-lg-8 align-self-center'>

              <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-indicators d-none">
                  <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to={0} className="active" aria-current="true" aria-label="Slide 1" />
                  <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to={1} aria-label="Slide 2" />
                  <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to={2} aria-label="Slide 3" />
                </div>
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <img src="./images/shipping.jpg" className="d-block w-100" alt="..." />
                    <div className="carousel-caption  d-md-block">
                      <h5>First slide label</h5>
                      <p>Some representative placeholder content for the first slide.</p>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <img src="./images/inspections.jpg" className="d-block w-100" alt="..." />
                    <div className="carousel-caption  d-md-block">
                      <h5>Second slide label</h5>
                      <p>Some representative placeholder content for the second slide.</p>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <img src="./images/transport.jpg" className="d-block w-100" alt="..." />
                    <div className="carousel-caption  d-md-block">
                      <h5>Third slide label</h5>
                      <p>Some representative placeholder content for the third slide.</p>
                    </div>
                  </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                  <span className="carousel-control-prev-icon" aria-hidden="true" />
                  <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                  <span className="carousel-control-next-icon" aria-hidden="true" />
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default MyGallery;
