import React, { useState } from "react";
import Spinner from "../Spinner/spinner";

export default function ProductCard({ products, sold }) {
  const [loadedImages, setLoadedImages] = useState({});

  const handleImageLoad = (index) => {
    setLoadedImages((prevLoadedImages) => ({
      ...prevLoadedImages,
      [index]: true,
    }));
  };

  return (
    <div className="row mx-10">
      {products ? (
        products &&
        products.map((prod, _i) => {
          return (
            <div
              key={_i}
              className="col-lg-3 col-md-6 px-10 mb-3 aos-init aos-animate"
            >
              <div className="eq_product eq_brands">
                <div className={`${sold}`}></div>
                <div className="image">
                  <a href={`/details?id=${prod._id}`}>
                    <img
                      src={`${process.env.REACT_APP_SERVER_URL}/${prod.images[0]}`}
                      alt={prod.name}
                      className=" lazyloaded products-img"
                      loading="lazy"
                    />
                  </a>

                  <p>$ {prod.price === 0 ? "REQUEST FOR PRICE" : prod.price}</p>
                </div>
                <div className="content">
                  <h3>
                    <a href={`/details?id=${prod._id}`}>{prod.name}</a>
                  </h3>
                  <ul>
                    <li>
                      <i className="bi bi-clock"></i>
                      <b>Hours: </b> {prod.hours}
                    </li>
                    <li>
                      <i className="bi bi-tag-fill"></i>
                      <b>Serial: </b> {prod.serial}
                    </li>
                    <li>
                      <i className="bi bi-list-task"></i>
                      <b>Model: </b> {prod.model}
                    </li>
                    <li>
                      <i className="bi bi-geo-alt-fill"></i>
                      <b>Location: </b> {prod.location}
                    </li>
                  </ul>
                  <a
                    href={`/details?id=${prod._id}`}
                    className="cbtn_dark mt-20 w-100 text-center"
                  >
                    View More
                  </a>
                </div>
              </div>
            </div>
          );
        })
       ) : (
        <Spinner />
      )}
    </div>
  );
}
