import React, { useEffect, useState } from "react";
import "./sold.css";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import ListProductsSlice from "../../store/action/listProductsSlice";
import ProductCard from "../../Components/ProductCard/productCard";
import Spinner from "../../Components/Spinner/spinner";
import { scrollToTop } from "../../functions/scrollToTop";

const SoldList = () => {
  const productList = useSelector((state) => state.productsList);

  const dispatch = useDispatch();
  const [soldProducts, setSoldPRoducts] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMoreProducts, setHasMoreProducts] = useState(true);

  const getProducts = async () => {
    setIsLoading(true);
    // if (!productList) {
    const res = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/products?limit=5&page=0&search=&category=&brand=&model&year=&sold=true`
    );
    // dispatch(ListProductsSlice(res.data));
    setSoldPRoducts(res.data);
    console.log(res.data);
    setIsLoading(false);
    // }
  };
  const pagination = async () => {
    setIsLoading(true);
    const res = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/products?limit=5&page=${page}&search=&category=&brand=&model&year=&sold=true`
    );
    const newProducts = res.data;
    setSoldPRoducts((prevStockList) => [...prevStockList, ...newProducts]);
    if (newProducts.length < 5) {
      setHasMoreProducts(false);
    }
    setPage((prev) => prev + 1);
    setIsLoading(false);
  };
  useEffect(() => {
    getProducts();
    scrollToTop();
  }, [dispatch]);
  return (
    <main>
      {/* banner section */}
      <section className="sold-list_banner">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 align-self-center">
              <div
                className="equipment_banner_slider carousel carousel-fade slide"
                id="heroCarousel"
                data-ride="carousel"
              >
                <div
                  id="carouselExample Autoplaying"
                  className="carousel slide"
                  data-bs-ride="carousel"
                >
                  <div className="carousel-inner">
                    <div className="carousel-item active" style={{}}>
                      <div className="carousel-container">
                        <h2 className="lg_text animate__animated animate__fadeInLeft animate__slow">
                          Great Machines
                          <span className="d-block">From Leading</span>
                          <span className="d-block">Manufacturers</span>
                        </h2>
                        <div className="divider  animate__animated animate__fadeInLeft animate__delay-0.5s"></div>
                        <h1
                          className="md_text aos-init aos-animate"
                          data-aos="fade-right"
                          data-aos-delay={600}
                          data-aos-duration={1500}
                          style={{ fontWeight: "normal" }}
                        >
                          Equipment For Sale
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* sold equipment section */}
      <section
        className="inventory_section py-5"
        style={{ backgroundImage: "none" }}
      >
        <div className="container">
          <div className="title_with_sep text-center aos-init aos-animate">
            <h3>SOLD EQUIPMENT</h3>
            <div className="divider m-auto"></div>
          </div>
          <div className="row mx-10 aos-init aos-animate">
            <div className="col-md-8 text-md-left text-center pb-3 px-10">
              <p className="sorting_text text-start">
                Showing 1 - {soldProducts && soldProducts.length}
              </p>
            </div>
          </div>
          {isLoading && page === 1 ? (
            <div className="text-center my-4">
              <Spinner />
            </div>
          ) : (
            <ProductCard products={soldProducts} sold={"sold-label"}/>
          )}
          <div className="text-center">
            {isLoading && page > 1 ? (
              <Spinner />
            ) : (
              hasMoreProducts && (
                <button className="cbtn_dark mt-4 cursor" onClick={pagination}>
                  Load More
                </button>
              )
            )}
          </div>

         
        </div>
      </section>
    </main>
  );
};

export default SoldList;
