import React, { useEffect, useState } from "react";
import "./megaSearch.css";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import ProductCard from "../../Components/ProductCard/productCard";
import { scrollToTop } from "../../functions/scrollToTop";
import Spinner from "../../Components/Spinner/spinner";

const MegaSearch = () => {
  // get value form input search
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const result = queryParams.get("result");
  const [searchData, setSearchData] = useState([]);
  const [filterDate, setFilterDate] = useState([]);

  const filters = useSelector((state) => state.detailedSearch);

  const getSearchResult = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/products?limit=50&page=0&search=${result}&category=&brand=&model&year=`
    );
    setSearchData(res.data);
  };
  useEffect(() => {
    getSearchResult();
    scrollToTop();
  }, [result]);
  return (
    <main>
      <section className="an-used-equ-section an-section-padding mt-4">
        <div className="container">
          <h2 className="an-primary-heading an-center aos-init aos-animate">
            Search Results
          </h2>

          <div className="an-latest-inventary-breadcrum aos-init aos-animate">
            <div>
              showing 1 -{" "}
              {searchData
                ? searchData.length
                : filterDate
                ? filterDate.length
                : 0}
            </div>
            <div className="my-1">
              <span> HOME </span>
              <span> |</span>
              <span> SEARCH RESULTS</span>
              <span> |</span>
            </div>
          </div>

          {/* start product */}
          {!filters ? (
            <ProductCard products={searchData} />
          ) : (
            <ProductCard products={filters} />
          )}

          {/* <div className="row an-space-4">
            {searchData &&
              searchData.map((data, index) => {
                return (
                  <div
                    key={index}
                    className="inventory_product an-product-box col-sm-6 col-md-3 col-lg-4"
                  >
                    <div className="image">
                      <a href="/">
                        <img
                          src={`${process.env.REACT_APP_SERVER_URL}/${data.images[0]}`}
                          alt={data.name}
                          className=" lazyloaded"
                          id="img-search"
                        />
                      </a>
                      <p>$ {data.price}</p>
                    </div>
                    <h3 className="ip_content an-product-content">
                      <a href="/">{data.name}</a>
                      <span>{data.location}</span>
                    </h3>
                  </div>
                );
              })}
            {filters &&
              filters.map((data, index) => {
                return (
                  <div key={index} className="inventory_product an-product-box col-sm-6 col-md-3 col-lg-4">
                    <div className="image">
                      <a href="/">
                        <img
                          src={`${process.env.REACT_APP_SERVER_URL}/${data.images[0]}`}
                          alt={data.name}
                          className=" lazyloaded"
                        />
                      </a>
                      <p>$ {data.price}</p>
                    </div>
                    <h3 className="ip_content an-product-content">
                      <a href="/">{data.name}</a>
                      <span>{data.location}</span>
                    </h3>
                  </div>
                );
              })}
          </div> */}
        </div>
      </section>
    </main>
  );
};

export default MegaSearch;
