import React from 'react';
import SoldEquipment from '../../Components/Sold Equipment/soldEquipment';

const SoldMotorGraders = () => {
    return (
        <div>
            <SoldEquipment cat="Motor Graders" name="motor-graders"/>
        </div>
    );
}

export default SoldMotorGraders;
