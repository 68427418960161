import React from "react";
import "./home.css";
import Banner from "../../Components/Banner/banner";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import DetailedSearch from "../../store/action/detailedSearch";
import Blog from "../../Components/Blog/blog";
import { handleAlert } from "../../functions/handleAlert";
import Brands from "../../Components/Brands/brands";
import MyGallery from "../../Components/imageGallery/MyGallery";
import Spinner from "../../Components/Spinner/spinner";
const Home = () => {
  const [products, setProducts] = useState([]);

  const [showButton, setShowButton] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadedImages, setLoadedImages] = useState({});
  const catAndBrands = useSelector((state)=>state.categoriesBrands);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // function to get all products
  const getAllProducts = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/products?limit=12&page=0&search=&category=&brand=&model&year=&sold=false`
    );
    setProducts(res.data);
    setIsLoading(true);
    // console.log(res.data);
  };
  // function to get all Category and brand
 

  // function to handle Detailed search
  const handelDetailedSearch = (event) => {
    event.preventDefault();
    let cat = event.target.category.value;
    let brand = event.target.brand.value;
    let model = event.target.model.value;
    let year = event.target.year.value;
    axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/products?limit=12&page=0&search=&category=${cat}&brand=${brand}&model=${model}&year=${year}`
      )
      .then((res) => {
        dispatch(DetailedSearch(res.data));
        // console.log(res.data);
        navigate(`/mega-search`);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // function to send form to suggestion
  const sendRequest = async (event) => {
    event.preventDefault();
    setShowButton(true);
    let cat = event.target.category.value;
    let brand = event.target.brand.value;
    let model = event.target.model.value;
    let price = parseInt(event.target.price.value);
    let year = parseInt(event.target.year.value);
    let email = event.target.email.value;
    let message = event.target.description.value;
    // console.log(cat, brand, model, year, price, email, message);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/suggestion`,
        {
          email: email,
          message: message,
          equipment: cat,
          brand: brand,
          model: model,
          price: price,
          year: year,
        }
      );
      handleAlert(res.data.message, "success");
      // console.log(res.data);
      setShowButton(false);
    } catch (error) {
      handleAlert(error.response.data.message, "error");
    }
  };

  const handleImageLoad = (index) => {
    setLoadedImages((prevLoadedImages) => ({
      ...prevLoadedImages,
      [index]: true,
    }));
  };

  useEffect(() => {
    getAllProducts();
  }, [dispatch]);

  return (
    <>
      <Banner />

      {/* Latest Inventory section */}
      {isLoading ? (
        <main
          className="inventory_section home-inventory_section py_60"
          style={{ backgroundSize: "contain" }}
        >
          <div className="container11 aos-init aos-animate">
            <div className="title_with_sep text-center mb_55 mb-3">
              <h3>Latest Inventory</h3>
              <div className="divider m-auto"></div>
            </div>

            {/* products section */}
            <div className="home-inventory_product_slider slick-initialized slick-slider">
              <div className="slick-list draggable">
                <div
                  className="slick-track"
                  style={{
                    opacity: 1,
                    transform: "translate3d(0px, 0px, 0px)",
                  }}
                >
                  <div className="slick-slide slick-current slick-active container-fluid">
                    <div className="row grid-product">
                      {/* {products &&
                        products.map((prod, index) => {
                          return (
                            <div key={index}
                              className="inventory_product col-6 col-sm-6 col-md-4 col-lg-3 col-xl-2"
                              style={{ display: "inline-block" }}
                            >
                              <div className="image">
                                <div className="new-label" />
                                <a
                                  href="https://web.archive.org/web/20230203121152/https://www.my-equipment.com/wheel-loaders/caterpillar-926e-94z02425"
                                  tabIndex={0}
                                >
                                  <img
                                    src={`${process.env.REACT_APP_SERVER_URL}/${prod.images[0]}`}
                                    alt={prod.name}
                                    className=" lazyloaded"
                                  />
                                </a>
                                <p>$ 28,000</p>
                              </div>
                              <h3 className="ip_content">
                                <a
                                  href="https://web.archive.org/web/20230203121152/https://www.my-equipment.com/wheel-loaders/caterpillar-926e-94z02425"
                                  tabIndex={0}
                                >
                                  1988 Caterpillar 926E
                                </a>{" "}
                                <span>Houston, Texas, USA</span>
                              </h3>
                            </div>
                          );
                        })} */}

                      {products &&
                        products.map((item, index) => (
                          <div
                            key={index}
                            className="inventory_product col-6 col-sm-6 col-md-4 col-lg-3 col-xl-2 my-3"
                          >
                            <div className="image">
                              <div className="new-label"></div>
                              <a href={`/details?id=${item._id}`}>
                                {!loadedImages[index] && (
                                  <img
                                    src={`./images/lazyloader.gif`}
                                    alt="Loading..."
                                    className="lazyloader"
                                  />
                                )}
                                <img
                                
                                  src={`${process.env.REACT_APP_SERVER_URL}/${item.images[0]}`}
                                  alt={item.name}
                                  onLoad={() => handleImageLoad(index)}
                                  style={{
                                    display: loadedImages[index]
                                      ? "block"
                                      : "none",
                                  }}
                                  className="product-image"
                                />
                              </a>
                              <p >$ {item.price === 0 ? "ASK FOR PRICE" : item.price}</p>
                            </div>
                            <h3 className="ip_content">
                              <a href={`/details?id=${item._id}`}>
                                {item.name}
                              </a>
                              <span>{item.location}</span>
                            </h3>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="inventory_section-btn text-center">
              <a href="/stock-list" className="cbtn_dark mt-20">
                View all products
              </a>
            </div>
          </div>
        </main>
      ) : (
        <div className="text-center my-4">
          <Spinner />
        </div>
      )}

      {/* Latest ATTACHMENT  section */}
      {isLoading ? (
        <main
          className="inventory_section home-inventory_section py_60 my-4"
          style={{ backgroundSize: "contain" }}
        >
          <div className="container11 aos-init aos-animate">
            <div className="title_with_sep text-center mb_55 mb-3">
              <h3>New Attachment</h3>
              <div className="divider m-auto"></div>
            </div>

            {/* products section */}
            <div className="home-inventory_product_slider slick-initialized slick-slider">
              <div className="slick-list draggable">
                <div
                  className="slick-track"
                  style={{
                    opacity: 1,
                    transform: "translate3d(0px, 0px, 0px)",
                  }}
                >
                  <div className="slick-slide slick-current slick-active container-fluid">
                    <div className="row grid-product">
                      {products &&
                        products.map((product, index) => {
                          if (product.category === "Attachments") {
                            return (
                              <div
                                key={index}
                                className="inventory_product col-6 col-sm-6 col-md-4 col-lg-3 col-xl-2 my-3"
                              >
                                <div className="image">
                                  <div className="new-label"></div>
                                  <a href={`/details?id=${product._id}`}>
                                    <img
                                      src={`${process.env.REACT_APP_SERVER_URL}/${product.images[0]}`}
                                      alt={product.name}
                                      className=" lazyloaded"
                                    />
                                  </a>
                                  <p>
                                    ${" "}
                                    {product.price === 0
                                      ? "CALL"
                                      : product.price}
                                  </p>
                                </div>
                                <h3 className="ip_content">
                                  <a href={`/details?id=${product._id}`}>
                                    {product.name}
                                  </a>
                                  <span>{product.location}</span>
                                </h3>
                              </div>
                            );
                          } else {
                            return null;
                          }
                        })}

                      {/* <div className='inventory_product  col-6 col-sm-6 col-md-3 col-lg-3' >
                                            <div className='image'>
                                                <div className='new-label'></div>
                                                <a href="/">
                                                    <img src="https://cdn1.my-equipment.com/imgjpg/290x212/products/_3087_1.jpg" alt="2010 Caterpillar 336DL W3K01164 W3K01164" className=' lazyloaded' />
                                                </a>
                                                <p>$ 58,000</p>

                                            </div>
                                            <h3 className="ip_content">
                                                <a href="/">2010 Caterpillar 336DL</a>
                                                <span>North Carolina, USA</span>
                                            </h3>

                                        </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="inventory_section-btn text-center">
              <a href="/attachments" className="cbtn_dark mt-20">
                View all products
              </a>
            </div>
          </div>
        </main>
      ) : (
        <div className="text-center my-4">
          <Spinner />
        </div>
      )}

      {/* Latest Parts  section */}
      {isLoading ? (
        <main
          className="inventory_section home-inventory_section py_60 my-4"
          style={{ backgroundSize: "contain" }}
        >
          <div className="container11 aos-init aos-animate">
            <div className="title_with_sep text-center mb_55 mb-3">
              <h3>Parts </h3>
              <div className="divider m-auto"></div>
            </div>

            {/* products section */}
            <div className="home-inventory_product_slider slick-initialized slick-slider">
              <div className="slick-list draggable">
                <div
                  className="slick-track"
                  style={{
                    opacity: 1,
                    transform: "translate3d(0px, 0px, 0px)",
                  }}
                >
                  <div className="slick-slide slick-current slick-active container-fluid">
                    <div className="row grid-product">
                      {products &&
                        products.map((product, index) => {
                          if (product.category === "Parts") {
                            return (
                              <div
                                key={index}
                                className="inventory_product col-6 col-sm-6 col-md-4 col-lg-3 col-xl-2 my-3"
                              >
                                <div className="image">
                                  <div className="new-label"></div>
                                  <a href={`/details?id=${product._id}`}>
                                    <img
                                      src={`${process.env.REACT_APP_SERVER_URL}/${product.images[0]}`}
                                      alt={product.name}
                                      className=" lazyloaded"
                                    />
                                  </a>
                                  <p>
                                    ${" "}
                                    {product.price === 0
                                      ? "CALL"
                                      : product.price}
                                  </p>
                                </div>
                                <h3 className="ip_content">
                                  <a href={`/details?id=${product._id}`}>
                                    {product.name}
                                  </a>
                                  <span>{product.location}</span>
                                </h3>
                              </div>
                            );
                          } else {
                            return null;
                          }
                        })}

                      {/* <div className='inventory_product  col-6 col-sm-6 col-md-3 col-lg-3' >
                                            <div className='image'>
                                                <div className='new-label'></div>
                                                <a href="/">
                                                    <img src="https://cdn1.my-equipment.com/imgjpg/290x212/products/_3087_1.jpg" alt="2010 Caterpillar 336DL W3K01164 W3K01164" className=' lazyloaded' />
                                                </a>
                                                <p>$ 58,000</p>

                                            </div>
                                            <h3 className="ip_content">
                                                <a href="/">2010 Caterpillar 336DL</a>
                                                <span>North Carolina, USA</span>
                                            </h3>

                                        </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="inventory_section-btn text-center">
              <a href="/parts" className="cbtn_dark mt-20">
                View all products
              </a>
            </div>
          </div>
        </main>
      ) : (
        <div className="text-center my-4">
          <Spinner />
        </div>
      )}

      {/* Detailed search section */}
      <section className="detail_search mb-5">
        <div className="container ">
          <h3 className="border_title aos-init aos-animate" data-aos="fade-up">
            Detailed Search
          </h3>
          <form onSubmit={handelDetailedSearch}>
            <div className="form_select aos-init aos-animate">
              <label>Equipment</label>
              <select
                className="form-control select2-hidden-accessible"
                required
                name="category"
                id="categoryDetailedSearch"
                data-select2-id="select2-data-categoryDetailedSearch"
                tabIndex="-1"
                aria-hidden="true"
                style={{ cursor: "pointer", padding: "10px" }}
              >
                <option value="" data-select2-id="select2-data-2-bkqy">
                  Select Equipment
                </option>
                {catAndBrands &&
                  catAndBrands.categories.map((cat, index) => {
                    return (
                      <option
                        key={index}
                        value={cat.name}
                        data-select2-id="select2-data-592-omoj"
                      >
                        {cat.name}
                      </option>
                    );
                  })}
              </select>
              <span
                className="select2 select2-container cursor select2-container--default select2-container--below select2-container--focus "
                style={{ width: "215px" }}
              >
                {/* <span className='selection cursor'>
                                    <span className="select2-selection select2-selection--single cursor">
                                        <span className='select2-selection__rendered' title='Select Equipment'>Select Equipment</span>
                                        <span className='select2-selection__arrow cursor'>
                                            <b role='presentation'></b>
                                        </span>
                                    </span>
                                </span> */}
              </span>
            </div>
            <div className="form_select aos-init aos-animate">
              <label>BRAND</label>
              <select
                className="form-control select2-hidden-accessible"
                name="brand"
                id="brandDetailedSearch"
                required
                data-select2-id="select2-data-brandDetailedSearch"
                tabIndex="-1"
                aria-hidden="true"
                style={{ cursor: "pointer", padding: "10px" }}
              >
                <option value="" data-select2-id="select2-data-2-bkqy">
                  All
                </option>
                {catAndBrands &&
                  catAndBrands.brands.map((brand, index) => {
                    return (
                      <option
                        key={index}
                        value={brand.name}
                        data-select2-id="select2-data-592-omoj"
                      >
                        {brand.name}
                      </option>
                    );
                  })}
              </select>
              <span
                className="select2 select2-container cursor select2-container--default select2-container--below select2-container--focus "
                style={{ width: "215px" }}
              >
                {/* <span className='selection cursor'>
                                    <span className="select2-selection select2-selection--single cursor">
                                        <span className='select2-selection__rendered' title='Select Equipment'>Select Equipment</span>
                                        <span className='select2-selection__arrow cursor'>
                                            <b role='presentation'></b>
                                        </span>
                                    </span>
                                </span> */}
              </span>
            </div>
            <div className="form_select aos-init aos-animate">
              <label>MODEL</label>
              <div className="">
                <input
                  type="text"
                  name="model"
                  id="model"
                  placeholder="Model"
                  className="form-control"
                  style={{ height: "47px" }}
                />
              </div>
            </div>
            <div className="form_select aos-init aos-animate">
              <label>YEAR</label>
              <input
                type="number"
                name="year"
                id="year"
                placeholder="YEAR"
                className="form-control"
                style={{ height: "47px" }}
              />
            </div>
            <div className="form_select aos-init aos-animate mr-0">
              <button type="submit" className="btn">
                <i className="bi bi-send-fill"></i> Search Machine
              </button>
            </div>
          </form>
        </div>
      </section>

      {/* our Service section */}
      {/* <Services />  */}

      {/* new arrival section */}
      {/* <section className='new_arrival_section py-5 px-2'>
                <div className='container aos-init aos-animate '>
                    <div className='title_with_sep text-center'>
                        <h3>New Arrivals</h3>
                        <div className="divider m-auto"></div>
                        <a href="/" className="cbtn_dark mt-4 fw-semibold">Video Gallery</a>
                    </div>
                    <div className="row">
                        <div className="inventory_product col-lg-5 col-md-5 col-sm-12 mx-auto">
                            <div className="image">
                                <a href="/" className='video_popup'>
                                    <div style={{ background: '#27384666', width: '100%', height: '100%', position: 'absolute' }}>
                                        <div style={{ margin: '0 auto', transform: 'translate(-50%, -50%)', WebkitTransform: 'translate(-50%, -50%)', textAlign: 'center' }}>

                                        </div>

                                    </div>
                                    <img src="https://i.ytimg.com/vi_webp/jcXZg743iyI/sddefault.webp" alt="2003 CATERPILLAR 980GII" className="lazyloaded" />
                                </a>
                                <p>$ 49,500</p>
                            </div>
                            <h3 className='ip_content'>
                                <a href="/" style={{ textTransform: 'uppercase' }}>2006 Dynapac CA602PD</a>
                                <span>
                                    <ul className='single_eq_list mt-1 mb-2 aos-init aos-animate'>
                                        <li>
                                            <i className="bi bi-calendar2-event"></i> &nbsp;
                                            Year: 2006
                                        </li>
                                        <li>
                                            <i className="bi bi-clock"></i> &nbsp;
                                            Hours: 6,580
                                        </li>
                                        <li>
                                            <i className="bi bi-list-ul"></i> &nbsp;
                                            Model: CA602PD
                                        </li>
                                        <li>
                                            <i className="bi bi-tag-fill"></i> &nbsp;
                                            Serial: 71521076
                                        </li>
                                        <li>
                                            <i className="bi bi-geo-alt-fill"></i> &nbsp;
                                            Location: Houston, Texas, USA
                                        </li>
                                    </ul>
                                </span>
                            </h3>
                            <a href="/" className="cbtn_dark mt-20 details">
                                <i className="bi bi-eye"></i> Photos & Details
                            </a>

                        </div>
                        <div className="inventory_product col-lg-5 col-md-5 col-sm-12 mx-auto">
                            <div className="image">
                                <a href="/" className='video_popup'>
                                    <div style={{ background: '#27384666', width: '100%', height: '100%', position: 'absolute' }}>
                                        <div style={{ margin: '0 auto', transform: 'translate(-50%, -50%)', WebkitTransform: 'translate(-50%, -50%)', textAlign: 'center' }}>

                                        </div>

                                    </div>
                                    <img src="https://i.ytimg.com/vi_webp/jcXZg743iyI/sddefault.webp" alt="2003 CATERPILLAR 980GII" className="lazyloaded" />
                                </a>
                                <p>$ 49,500</p>
                            </div>
                            <h3 className='ip_content'>
                                <a href="/" style={{ textTransform: 'uppercase' }}>2006 Dynapac CA602PD</a>
                                <span>
                                    <ul className='single_eq_list mt-1 mb-2 aos-init aos-animate'>
                                        <li>
                                            <i className="bi bi-calendar2-event"></i> &nbsp;
                                            Year: 2006
                                        </li>
                                        <li>
                                            <i className="bi bi-clock"></i> &nbsp;
                                            Hours: 6,580
                                        </li>
                                        <li>
                                            <i className="bi bi-list-ul"></i> &nbsp;
                                            Model: CA602PD
                                        </li>
                                        <li>
                                            <i className="bi bi-tag-fill"></i> &nbsp;
                                            Serial: 71521076
                                        </li>
                                        <li>
                                            <i className="bi bi-geo-alt-fill"></i> &nbsp;
                                            Location: Houston, Texas, USA
                                        </li>
                                    </ul>
                                </span>
                            </h3>
                            <a href="/" className="cbtn_dark mt-20 details">
                                <i className="bi bi-eye"></i> Photos & Details
                            </a>

                        </div>
                        
                    </div>
                </div>
            </section> */}

      {/* Want specific construction equipment */}
      <section className="custruction_section">
        <div className="container">
          <h1 className="border_title aos-init aos-animate">
            Want specific construction equipment ?
          </h1>
          <div className="row">
            <div className="col-lg-5 aos-init aos-animate">
              <h3 className="inner_title pt-3 mb-3">Equipment On Demand</h3>
              <form onSubmit={sendRequest}>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="mb-4">
                      <label>Equipment</label>
                      <select
                        className="form-control select2-hidden-accessible"
                        name="category"
                        id="categoryDetailedSearch"
                        data-select2-id="select2-data-categoryDetailedSearch"
                        tabIndex="-1"
                        aria-hidden="true"
                        style={{ cursor: "pointer", height: "47px" }}
                      >
                        <option value data-select2-id="select2-data-2-bkqy">
                          All Equipment
                        </option>
                        {catAndBrands &&
                          catAndBrands.categories.map((cat, index) => {
                            return (
                              <option
                                key={index}
                                value={cat.name}
                                data-select2-id="select2-data-592-omoj"
                              >
                                {cat.name}
                              </option>
                            );
                          })}
                      </select>
                      <span
                        className="select2 select2-container cursor select2-container--default select2-container--below select2-container--focus "
                        style={{ width: "215px" }}
                      ></span>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="mb-4">
                      <label>BRAND</label>
                      <select
                        className="form-control select2-hidden-accessible"
                        name="brand"
                        id="brandDetailedSearch"
                        data-select2-id="select2-data-brandDetailedSearch"
                        tabIndex="-1"
                        aria-hidden="true"
                        style={{ cursor: "pointer", height: "47px" }}
                      >
                        <option value data-select2-id="select2-data-2-bkqy">
                          All
                        </option>
                        {catAndBrands &&
                          catAndBrands.brands.map((brand, index) => {
                            return (
                              <option
                                key={index}
                                value={brand.name}
                                data-select2-id="select2-data-592-omoj"
                              >
                                {brand.name}
                              </option>
                            );
                          })}
                      </select>
                      <span
                        className="select2 select2-container cursor select2-container--default select2-container--below select2-container--focus "
                        style={{ width: "215px" }}
                      ></span>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="mb-4">
                      <label>MODEL</label>
                      <input
                        type="text"
                        name="model"
                        id="model"
                        placeholder="Model"
                        className="form-control"
                        required="required"
                        style={{ height: "47px" }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="mb-4">
                      <label>PRICE</label>
                      <div className="input_price">
                        <input
                          type="number"
                          name="price"
                          id="price"
                          placeholder="Price"
                          className="form-control"
                          required="required"
                          style={{ height: "47px" }}
                        />
                      </div>
                      <span
                        className="select2 select2-container cursor select2-container--default select2-container--below select2-container--focus "
                        style={{ width: "215px" }}
                      ></span>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="mb-4">
                      <label>YEAR</label>
                      <input
                        type="number"
                        name="year"
                        id="year"
                        placeholder="Year"
                        className="form-control"
                        required="required"
                        style={{ height: "47px" }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="mb-4">
                      <label>Email Address</label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Your Email"
                        className="form-control"
                        required="required"
                        style={{ height: "47px" }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="mb-4">
                      <label>Description</label>
                      <textarea
                        name="description"
                        id="description"
                        placeholder="Type your message here"
                        required="required"
                        className="form-control"
                        defaultValue={""}
                        style={{ height: "47px" }}
                      />
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="mb-4">
                      {!showButton ? (
                        <button type="submit" className="btn btn_orange">
                          <i className="bi bi-send-fill"></i> SEND US now
                        </button>
                      ) : (
                        <button type="submit">
                          <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-lg-7 mobile_none aos-init aos-animate d-none d-lg-block">
              {/* <h2 className='inner_title pt-3 mb-3'>
                                Heavy Machinery for Construction
                            </h2> */}
              <p className="text-white">
                We will supply you with the hottest deals of machines you want,
                We pleased to offer used machinery for sale from some of the
                world's top construction suppliers.
              </p>
              {/* <p className="text-white">
                                We are proud to present used machinery for sale from some of the world's
                                leading construction brands. This includes Hitachi, Komatsu, Caterpillar, Volvo, Massey
                                Ferguson, and John Deere. All used construction machinery is sold as seen after undergoing full
                                testing and refurbishment with full documentation included.
                            </p>
                            <h3 className='inner_title pt-3 mb-3'>
                                Great Value on Heavy Equipment

                            </h3>
                            <p className="text-white">
                                Buying equipment is an investment that your business needs to get right. That
                                is why we only stock and sell safe, fully tested machinery that we would be comfortable using
                                ourselves. As leaders in the industry of heavy equipment in Houston, TX, we have been serving
                                Houston and surrounding Texas towns for many years and have one of the best selections of used
                                heavy equipment for sale in Texas, and we have a superb reputation with local companies and
                                contractors.
                            </p>
                            <p className="text-white">
                                One of the main reasons customers come back time and again is the exceptional
                                value we offer compared to other heavy machinery dealers. We know that every cent counts when
                                you have a company to run, so we ensure that you get the best possible deal on the machines you
                                need. Great service from our friendly team is all part of the package too.
                            </p> */}
              <a href="/about-us" className="btn_link_orange">
                Learn more about GCES
              </a>
            </div>
          </div>
        </div>
      </section>

      {/* BLOG & NEWS section */}
      {/* <Blog /> */}

      {/* Our Brands */}
      <Brands />
    </>
  );
};

export default Home;
