import axios from 'axios';
import React, { useEffect } from 'react'
import ListCategoriesBrandsSlice from '../../store/action/listCategories-BrandsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { handleAlert } from '../../functions/handleAlert';
import { useState } from 'react';

export default function Suggestion() {

    const dispatch = useDispatch();
    const brandsAndCatList = useSelector(state => state.categoriesBrands);
    const [showButton, setShowButton] = useState(false);



    const sendRequest = async (event) => {
        event.preventDefault();
        setShowButton(true);

        let name = event.target.name.value;
        let email = event.target.email.value;
        let message = event.target.message.value;
        let cat = event.target.category.value;
        let brand = event.target.brand.value;
        let model = event.target.model.value;
        let year = parseInt(event.target.year.value);
        let price = parseInt(event.target.price.value);

        // console.log(cat, brand, model, year,price, email, message);
        try {
            const res = await axios.post(`${process.env.REACT_APP_SERVER_URL}/suggestion`,
                {
                    "email": email,
                    "message": message,
                    "equipment": cat,
                    "brand": brand,
                    "model": model,
                    "price": price,
                    "year": year
                })
            handleAlert(res.data.message, "success");
            // console.log(res.data);
            setShowButton(false);

        } catch (error) {
            handleAlert(error.response.data.message, "error");
        }
    }

    const getCategoriesBrands = async () => {
        if (!brandsAndCatList) {
            const res = await axios.get(`${process.env.REACT_APP_SERVER_URL}/products/categories-brands`);
            dispatch(ListCategoriesBrandsSlice(res.data));
            // setCategoriesBrands(res.data);
        }
        // setCategoriesBrands(brandsAndCatList);

    }

    useEffect(() => {
        // getCategoriesBrands();
    }, [dispatch])

    return (
        < section className='custruction_section-2' >
            <div className='container'>
                <h1 className='border_title aos-init aos-animate'>Want specific construction equipment?</h1>
                <div className="row">
                    <div className="col-lg-5 aos-init aos-animate">
                        <h3 className="inner_title pt-3 mb-3">
                            Equipment On Demand
                        </h3>
                        <form onSubmit={sendRequest}>
                            <div className="row">
                                <div className="col-md-6 pb-3">
                                    <label>Name</label>
                                    <input type="text" name="name" id="name" placeholder="Your Name" className="form-control" required="required" />
                                </div>
                                <div className="col-md-6 pb-3">
                                    <label>Your Email</label>
                                    <input type="email" name="email" id="email" placeholder="Your Email" className="form-control" required="required" />
                                </div>
                                <div className="col-lg-6">
                                    <div className='mb-4'>
                                        <label>CATEGORY</label>
                                        <select className="form-control select2-hidden-accessible" name="category" id="categoryDetailedSearch" required data-select2-id="select2-data-categoryDetailedSearch" tabIndex="-1" aria-hidden="true" style={{ cursor: "pointer", height: "47px" }}>
                                            <option value="" data-select2-id="select2-data-2-bkqy">All</option>
                                            {brandsAndCatList && brandsAndCatList.categories.map((cat, index) => {
                                                return (
                                                    <option key={index} value={cat.name} data-select2-id="select2-data-592-omoj">{cat.name}</option>
                                                )
                                            })}

                                        </select>
                                        <span className='select2 select2-container cursor select2-container--default select2-container--below select2-container--focus ' style={{ width: "215px" }}>
                                        </span>

                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className='mb-4'>
                                        <label>BRAND</label>
                                        <select className="form-control select2-hidden-accessible cursor" name="brand" id="brandDetailedSearch" data-select2-id="select2-data-brandDetailedSearch" tabIndex="-1" aria-hidden="true" style={{ height: "47px" }} required>
                                            <option value="" data-select2-id="select2-data-2-bkqy">All</option>
                                            {brandsAndCatList && brandsAndCatList.brands.map((brand, index) => {
                                                return (
                                                    <option key={index} value={brand.name} data-select2-id="select2-data-592-omoj">{brand.name}</option>
                                                )
                                            })}
                                        </select>
                                        <span className='select2 select2-container cursor select2-container--default select2-container--below select2-container--focus ' style={{ width: "215px" }}>
                                        </span>

                                    </div>
                                </div>
                                <div className="col-md-6 pb-3">
                                    <label>Model</label>
                                    <input type="text" name="model" id="model" placeholder="Type Model" className="form-control" required="required" />
                                </div>
                                <div className="col-md-6 pb-3">
                                    <label>Year</label>
                                    <input type="number" name="year" id="year" placeholder="Type Year" className="form-control" required="required" />
                                </div>
                                <div className="col-md-6 pb-3">
                                    <label>Price</label>
                                    <input type="number" name="price" id="price" placeholder="Type Price" className="form-control" required="required" />
                                </div>
                                {/* <div className="col-md-6 pb-lg-4 pb-3">
                           <label>Subject</label>
                           <input type="text" name="subject" id="subject" placeholder="Type subject" className="form-control" required="required" />
                       </div> */}
                                <div className='col-lg-6'>
                                    <div className="mb-4">
                                        <label>Message</label>
                                        <textarea name="message" id="message" placeholder="Type your message here" required="required" className="form-control" defaultValue={""} style={{ height: "100px" }} />
                                    </div>
                                </div>
                                <div className='col-lg-12'>
                                    <div className="mb-4">
                                        {!showButton ? <button type='submit' className='btn btn_orange'>
                                            <i className="bi bi-send-fill"></i> Send Message
                                        </button> : <button type='submit'>
                                            <div className="spinner-border" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        </button>}

                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="col-lg-7 pt-lg-3 mobile_none aos-init aos-animate" data-aos="fade-up" data-aos-delay={200}>
                        <div className="scroll_text in_tt">
                            <p>
                            </p>
                            <p></p>
                            <p>We will supply you with the hottest deals of machines you want, We are pleased to offer used machinery for sale from some of the world's top construction suppliers.</p>
                            <p> <strong>SEND US NOW</strong></p>
                            {/*                             
                            <h2>Need a motor grader for your next project?</h2>
                            <h2>Why buy used equipment?</h2>
                            <p>With so much quality <a href="/about-us">used equipment for sale</a> at any given time, there's really no reason to buy new motor graders. Here at MY Equipment, we understand buying a new motor grader is a big investment, which is why we stock used machines that do the same job but cost significantly less to purchase.</p>
                            <p>By purchasing used equipment like <strong>cat grader for sale</strong>, you might even find you're able to buy a second piece of gear as well as any attachments you need to complete your work to the highest possible standard. However, when buying used, you also avoid the initial depreciation that comes with purchasing new equipment and you have much more choice. This is proven by the incredible range of motor graders for sale in Texas that you can see listed on this page.</p>
                            <h2>Get the motor grader you need today</h2>
                            <p>MY Equipment has the best range of motor grader equipment for sale in Houston. With a selection of excellent features, including air conditioning, left-hand controls, snow covers, aux hydraulics, defroster fans and much more, simply browse our motor graders to find the model most suited to your project.</p>
                            <p>To find out more about our selection of motor graders, as well as the dozers, pavers, compactors and cranes we have for sale, send an email to <a href="mailto:advertising@gcesequipment.com">advertising@gcesequipment.com
                            </a> or fill in our online contact form.</p>
                            <p /> */}
                        </div>
                    </div>

                </div>
            </div>
        </section >
    )
}
