import React from "react";
import "./subscribe.css";
import { handleAlert } from "../../functions/handleAlert";
import axios from "axios";
import { useState } from "react";

const SubscribeEmail = () => {
  const [showButton, setShowButton] = useState(false);
  const subscribeForm = async (event) => {
    event.preventDefault();
    let email = event.target.email.value;
    // console.log(email);
    setShowButton(true);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/subscription`,
        {
          email: email,
        }
      );
      handleAlert(res.data.message, "success");
      // console.log(res.data);
      setShowButton(false);
    } catch (error) {
      handleAlert(error.response.data.message, "error");
    }
  };
  return (
    <main>
      <section className="thank-you-sec sbscribe-sec">
        <div className="container">
          <div className="thank-you-warp">
            <div className="thank-you-cnt">
              <div className="thank-you-icn subcribe-icn aos-init aos-animate">
                <h4>
                  <i className="bi bi-send-fill"></i>
                </h4>
              </div>
              <h1 className="aos-init aos-animate">
                BE THE FIRST TO GET
                <br />
                OUR GREAT DEALS
              </h1>
            </div>
            <div className="sbscribe-form">
              <form onSubmit={subscribeForm}>
                <div className="sub_inp aos-init aos-animate">
                  <i className="bi bi-envelope"></i>
                  <input
                    type="email"
                    name="email"
                    id="subscribe-email"
                    placeholder="Enter email to subscribe"
                    required
                  />
                </div>
                <div className="captcha_sub_button">
                  <div className="sub_inp-submit aos-init aos-animate">
                    {!showButton ? (
                      <button type="submit">
                        <i className="bi bi-send-fill jahaz mx-1"></i>
                        Subscribe Now
                      </button>
                    ) : (
                      <button type="submit">
                        <div className="spinner-border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
            <div className="thank-you-social sbscribe-social">
              <h5 className="aos-init aos-animate">Follow us on</h5>
              <ul>
                <li>
                  <a href="" className="mx-1">
                    <i className="bi bi-facebook" />
                  </a>
                </li>
                <li>
                  <a href="" className="mx-1">
                    <i className="bi bi-linkedin" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default SubscribeEmail;
