import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Spinner from "../../Components/Spinner/spinner";
import ProductCard from "../ProductCard/productCard";

const SoldEquipment = ({ cat, name }) => {
  const dispatch = useDispatch();
  const [soldProducts, setSoldProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMoreProducts, setHasMoreProducts] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const getProducts = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/products?limit=15&page=0&search=&category=${cat}&brand=&model&year=&sold=true`
    );
    setSoldProducts(res.data);
    setIsLoading(true);
    setHasMoreProducts(res.data.length === 15);
  };

  const pagination = async () => {
    const nextPage = page + 1;
    const res = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/products?limit=15&page=${nextPage}&search=&category=${cat}&brand=&model&year=&sold=true`
    );
    setSoldProducts((prevSoldProducts) => [...prevSoldProducts, ...res.data]);
    setHasMoreProducts(res.data.length === 15);
    setPage(nextPage);
  };

  useEffect(() => {
    getProducts();
  }, [dispatch, cat]);

  return (
    <main>
      <section className={`sold-${name}_banner`}>
        <div className="container">
          <div className="row">
            <div className="col-lg-8 align-self-center">
              <div
                className="equipment_banner_slider carousel carousel-fade slide"
                id="heroCarousel"
                data-ride="carousel"
              >
                <div
                  id="carouselExampleAutoplaying"
                  className="carousel slide"
                  data-bs-ride="carousel"
                >
                  <div className="carousel-inner">
                    <div className="carousel-item active" style={{}}>
                      <div className="carousel-container">
                        <h2 className="lg_text animate__animated animate__fadeInLeft animate__slow">
                          Great Machines
                          <span className="d-block">From Leading</span>
                          <span className="d-block">Manufacturers</span>
                        </h2>
                        <div className="divider animate__animated animate__fadeInLeft animate__delay-0.5s"></div>
                        <h1
                          className="md_text aos-init aos-animate"
                          data-aos="fade-right"
                          data-aos-delay={600}
                          data-aos-duration={1500}
                          style={{ fontWeight: "normal" }}
                        >
                          Equipment For Sale
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="inventory_section py-5"
        style={{ backgroundImage: "none" }}
      >
        <div className="container">
          <div className="title_with_sep text-center aos-init aos-animate">
            <h3>SOLD EQUIPMENT</h3>
            <div className="divider m-auto"></div>
          </div>
          <div className="row mx-10 aos-init aos-animate">
            <div className="col-md-8 text-md-left text-center pb-3 px-10">
              <p className="sorting_text text-start">
                Showing 1 - {soldProducts && soldProducts.length}
              </p>
            </div>
          </div>
          {isLoading ? (
            <ProductCard products={soldProducts} sold={"sold-label"}/>
          ) : (
            <div className="text-center my-4">
              <Spinner />
            </div>
          )}

          <div className="text-center">
            {hasMoreProducts && (
              <a className="cbtn_dark mt-4 cursor" onClick={pagination}>
                View More
              </a>
            )}
          </div>
        </div>
      </section>
    </main>
  );
};

export default SoldEquipment;
