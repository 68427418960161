import React, { useEffect, useState } from 'react';
import './footer.css';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import ListCategoriesBrandsSlice from '../../store/action/listCategories-BrandsSlice';


const Footer = () => {
    const [brands, setBrands] = useState([]);
    const brandsAndCats = useSelector(state => state.categoriesBrands);
    const dispatch = useDispatch();

    const getBrandsAndCats = async () => {
        if (!brandsAndCats) {
            const res = await axios.get(`${process.env.REACT_APP_SERVER_URL}/products/categories-brands`);
            setBrands(res.data.brands);
            dispatch(ListCategoriesBrandsSlice(res.data));
        }
    }
    useEffect(() => {
        // getBrandsAndCats();
    }, [])
    return (
        <footer className='aos-init aos-animate'>
            <div className='container'>
                <div className='footer_row row'>
                    <div className='col-md-12 col-lg-3 col_1'>
                        <a href="/" className="ps-3">
                            <svg width={173} height={74} xmlns="http://www.w3.org/2000/svg">
                                <image href="/images/logo.png" height={90} />
                                {/* <image href="images/logo.svg" width={173} height={74} />                        */}
                            </svg>

                            {/* </svg> */}
                        </a>
                        <h3 className="pt-3 ps-3">ABOUT GCES </h3>
                        <p>since 2012, GCES has been one of the leading wholesale heavy machinery companies in the US with capability of providing equipment Sales and attachments. Whether you are a large company, small business, subcontractor, or a homeowner-builder, GCES has the right machine to meet all your earthmoving and heavy equipment needs.</p>
                        <ul className="footer_social_icons mb-0">
                            <li><a href="https://www.facebook.com/" target="_blank" className="pl-0"><i className="bi bi-facebook"></i></a></li>
                            <li><a href="https://twitter.com/" target="_blank"><i className="bi bi-twitter-x" /></a></li>
                            <li><a href="https://www.instagram.com/" target="_blank"><i className="bi bi-instagram" /></a></li>
                            {/* <li><a href="https://www.pinterest.com/" target="_blank"><i className="bi bi-pinterest" /></a></li> */}
                            <li><a href="https://www.youtube.com/" target="_blank"><i className="bi bi-youtube" /></a></li>
                        </ul>
                        <p className="mb-0">Developed By<a href="https://switch-advertising.com/" target='_blank' style={{color:"red"}}> Switch </a>| © 2024 GCES</p>


                    </div>
                    <div className='col-md-12 col-lg-2 col_2 f_border'>
                        <h3>QUICK LINKS</h3>
                        <ul>
                            <li ><a href="/stock-list">Our Stock</a></li>
                            <li><a href="/about-us">About Us</a></li>
                            {/* <li><a href="/services">Dismantling</a></li> */}
                            <li><a href="/transport">Transport</a></li>
                            <li><a href="/inspections">Inspections</a></li>
                            <li><a href="/shipping">Shipping</a></li>
                            <li><a href="/contact-us">Contact Us</a></li>
                            <li><a href="/videos">Video Gallery</a></li>
                            <li className="ftr-bl"><a href="#">Blog</a></li>
                            <li><a href="#">Privacy Policy</a></li>
                            <li><a href="#">Return Policy</a></li>
                            {/* <li><a href="/privacy-policy">Privacy Policy</a></li>
                            <li><a href="/return-policy">Return Policy</a></li> */}
                            {/* <li><a href="/sitemap">Site Map</a></li> */}
                        </ul>

                    </div>
                    <div className='col-md-12 col-lg-2 col_2 f_border'>
                        <h3>EQUIPMENT</h3>
                        <ul>
                            <li><a href="/stock-list">All Categories</a></li>
                            <li><a href="/wheel-loaders">Wheel Loaders</a></li>
                            <li><a href="/motor-graders">Motor Graders</a></li>
                            <li><a href="/excavators">Excavators</a></li>
                            <li><a href="/mini-excavator">Mini Excavators</a></li>
                            {/* <li><a href="/wheel-excavators">Wheel Excavators</a></li> */}
                            <li><a href="/dozer">Dozers</a></li>
                            <li><a href="/backhoe">Backhoe</a></li>
                            {/* <li><a href="/stock-list">Forklift</a></li> */}
                            <li ><a href="/roller">Rollers</a></li>
                            <li><a href="/track-loader">Track Loaders</a></li>
                            <li><a href="/dump-truck">Dump Truck</a></li>
                            <li><a href="/parts">Parts </a></li>
                            <li><a href="/attachments">Attachments</a></li>
                        </ul>

                    </div>
                    {/* Brands Colmn */}
                    <div className='col-md-12 col-lg-3 col_4 f_border'>
                        <h3>BRAND</h3>
                        <div className='f_brands'>
                            {brandsAndCats && brandsAndCats.brands.map((brand, index) => {
                                return (
                                    <a key={index} href={`${brand.name.toLowerCase().replace(/\s+/g, '-')}`}>
                                        <img src={`${process.env.REACT_APP_SERVER_URL}/${brand.image}`} alt={brand.name} className=" lazyloaded  mx-lg-2" height={50} />
                                    </a>
                                )
                            })}

                            {/* <a href="/brands">
                                <img src="https://www.my-equipment.com/newsite/images/brands/icons/dynapac.png" data-src="https://www.my-equipment.com/newsite/images/brands/icons/dynapac.png" alt="DYNAPAC" className=" lazyloaded img-fluid " />
                            </a>
                            <a href="/brands">
                                <img src="https://www.my-equipment.com/newsite/images/brands/icons/tadano.png" data-src="https://www.my-equipment.com/newsite/images/brands/icons/tadano.png" alt="TADANO" className=" lazyloaded img-fluid " />
                            </a>
                            <a href="/brands">
                                <img src="https://www.my-equipment.com/newsite/images/brands/icons/CAT.png" data-src="https://www.my-equipment.com/newsite/images/brands/icons/CAT.png" alt="CAT" className=" lazyloaded img-fluid " />
                            </a>
                            <a href="/brands">
                                <img src="https://www.my-equipment.com/newsite/images/brands/icons/dynapac.png" data-src="https://www.my-equipment.com/newsite/images/brands/icons/dynapac.png" alt="DYNAPAC" className=" lazyloaded img-fluid " />
                            </a>
                            <a href="/brands">
                                <img src="https://www.my-equipment.com/newsite/images/brands/icons/tadano.png" data-src="https://www.my-equipment.com/newsite/images/brands/icons/tadano.png" alt="TADANO" className=" lazyloaded img-fluid " />
                            </a>
                            <a href="/brands">
                                <img src="https://www.my-equipment.com/newsite/images/brands/icons/CAT.png" data-src="https://www.my-equipment.com/newsite/images/brands/icons/CAT.png" alt="CAT" className=" lazyloaded img-fluid " />
                            </a>
                            <a href="/brands">
                                <img src="https://www.my-equipment.com/newsite/images/brands/icons/dynapac.png" data-src="https://www.my-equipment.com/newsite/images/brands/icons/dynapac.png" alt="DYNAPAC" className=" lazyloaded img-fluid " />
                            </a>
                            <a href="/brands">
                                <img src="https://www.my-equipment.com/newsite/images/brands/icons/tadano.png" data-src="https://www.my-equipment.com/newsite/images/brands/icons/tadano.png" alt="TADANO" className=" lazyloaded img-fluid " />
                            </a>
                            <a href="/brands">
                                <img src="https://www.my-equipment.com/newsite/images/brands/icons/CAT.png" data-src="https://www.my-equipment.com/newsite/images/brands/icons/CAT.png" alt="CAT" className=" lazyloaded img-fluid " />
                            </a>
                            <a href="/brands">
                                <img src="https://www.my-equipment.com/newsite/images/brands/icons/dynapac.png" data-src="https://www.my-equipment.com/newsite/images/brands/icons/dynapac.png" alt="DYNAPAC" className=" lazyloaded img-fluid " />
                            </a>
                            <a href="/brands">
                                <img src="https://www.my-equipment.com/newsite/images/brands/icons/tadano.png" data-src="https://www.my-equipment.com/newsite/images/brands/icons/tadano.png" alt="TADANO" className=" lazyloaded img-fluid " />
                            </a> */}
                        </div>
                    </div>
                    {/* Contact Colmn */}
                    <div className='col-md-12 col-lg-2 col_5 f_border'>
                        <h3>CONTACT</h3>
                        <ul className="f_address">
                            <li>
                                <i className="bi bi-geo-alt-fill" />
                                800 S Brookhurst St, Suit 3-B, Anaheim, CA 92804, USA
                            </li>
                            <li>
                                <i className="bi bi-whatsapp" />
                                <a href="https://api.whatsapp.com/send?phone=+16573476660&amp;text=Hello! GCES, i want to chat with you regarding a machine. Reply me asap." className="homepage-whatsapp-triggered">+1 (657) 347-6660</a>

                            </li>
                            <li>
                                <i className="bi bi-telephone-fill" />
                                <a href="tel:+16573476660" className="">+1 (657)-347-6660</a>
                                <a href="tel:+16573476660" className="">+1 (657) 347-6660</a>

                            </li>
                            <li>
                                <i className="bi bi-envelope" />
                                <a href="mailto:advertising@gcesequipment.com" className="mail">advertising@gcesequipment.com</a>
                            </li>
                        </ul>
                    </div>

                </div>

            </div>

        </footer>

    );
}

export default Footer;
