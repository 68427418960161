// import React, { useEffect, useState } from "react";
// import "./equipment.css";
// import axios from "axios";
// import { useDispatch, useSelector } from "react-redux";
// import ListProductsSlice from "../../store/action/listProductsSlice";
// import Blog from "../../Components/Blog/blog";
// import ListCategoriesBrandsSlice from "../../store/action/listCategories-BrandsSlice";
// import Suggestion from "../../Components/Suggestion Form/suggestion";
// import Spinner from "../../Components/Spinner/spinner";
// import { scrollToTop } from "../../functions/scrollToTop";
// import ProductCard from "../../Components/ProductCard/productCard";

// const Telehandler = () => {
//   const [products, setProducts] = useState([]);
//   const [soldProducts, setSoldProducts] = useState([]);
//   const [isLoading, setIsLoading] = useState(false);
//   const dispatch = useDispatch();
//   const brandsAndCatList = useSelector((state) => state.categoriesBrands);

//   const getAllProducts = async () => {
//     const res = await axios.get(
//       `${process.env.REACT_APP_SERVER_URL}/products?limit=50&page=0&search=&category=&brand=&model&year=`
//     );
//     dispatch(ListProductsSlice(res.data));
//     setIsLoading(true);

//     for (let index = 0; index < res.data.length; index++) {
//       if (
//         res.data[index].category === "Telehandler" &&
//         res.data[index].sold === false
//       ) {
//         // console.log("yes");
//         setProducts((prevProducts) => [...prevProducts, res.data[index]]);
//       } else if (
//         res.data[index].category === "Telehandler" &&
//         res.data[index].sold === true
//       ) {
//         setSoldProducts((prevSoldProducts) => [
//           ...prevSoldProducts,
//           res.data[index],
//         ]);
//       }
//     }
//   };

//   const getCategoriesBrands = async () => {
//     if (!brandsAndCatList) {
//       const res = await axios.get(
//         `${process.env.REACT_APP_SERVER_URL}/products/categories-brands`
//       );
//       dispatch(ListCategoriesBrandsSlice(res.data));
//       // setCategoriesBrands(res.data);
//     }
//     // setCategoriesBrands(brandsAndCatList);
//   };

//   useEffect(() => {
//     getAllProducts();
//     getCategoriesBrands();
//     scrollToTop();
//   }, [dispatch]);
//   return (
//     <>
//       {/* banner section */}
//       <section className="telehandler-banner">
//         <div className="container">
//           <div className="row">
//             <div className="col-lg-8 align-self-center">
//               <div
//                 className="equipment_banner_slider carousel carousel-fade slide"
//                 id="heroCarousel"
//                 data-ride="carousel"
//               >
//                 <div
//                   id="carouselExample Autoplaying"
//                   className="carousel slide"
//                   data-bs-ride="carousel"
//                 >
//                   <div className="carousel-inner">
//                     <div className="carousel-item active" style={{}}>
//                       <div className="carousel-container">
//                         <h2 className="lg_text animate__animated animate__fadeInLeft animate__slow">
//                           FIND YOUR NEXT
//                           <span className="d-block">telehandler</span>
//                           <span className="d-block">WITH GCES</span>
//                         </h2>
//                         <div className="divider  animate__animated animate__fadeInLeft animate__delay-0.5s"></div>
//                         <h1
//                           className="md_text aos-init aos-animate"
//                           data-aos="fade-right"
//                           data-aos-delay={600}
//                           data-aos-duration={1500}
//                           style={{ fontWeight: "normal" }}
//                         >
//                           Explore a variety of machine now
//                         </h1>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>

//       {/* all Equipment section */}
//       <section
//         className="inventory_section py-4 "
//         style={{ backgroundImage: "none" }}
//       >
//         <div className="container">
//           <div className="title_with_sep text-center aos-init aos-animate ">
//             <h3>USED Equipments</h3>
//             <div className="divider m-auto"></div>
//           </div>
//           <div className="row mx-10 aos-init aos-animate">
//             <div className="col-12 col-md-8 text-md-left text-center pb-3 px-10">
//               <p className="sorting_text">Showing 1 - {products.length}</p>
//             </div>
//             <div className="col-12 col-md-4 text-md-right text-center pb-3 px-10">
//               <ul className="sort_breadcrums">
//                 <li>
//                   <a href="/">HOME</a>
//                 </li>
//                 <li>Telehandler</li>
//               </ul>
//             </div>
//           </div>

//           {/* all stock of Products section */}
//           {isLoading ? (
//             <ProductCard products={products} />
//           ) : (
//             <div className="text-center my-4">
//               <Spinner />
//             </div>
//           )}
//         </div>
//       </section>

//       {/* Solid Item Section */}
//       <section
//         className="inventory_section py-5"
//         style={{ backgroundImage: "none" }}
//       >
//         <div className="container">
//           <div className="title_with_sep text-center aos-init aos-animate">
//             <h3>SOLD ITEMS</h3>
//             <div className="divider m-auto"></div>
//           </div>
//           <div className="row mx-10 aos-init aos-animate">
//             <div className="col-md-8 text-md-left text-center pb-3 px-10">
//               <p className="sorting_text text-start">
//                 Showing 1 - {soldProducts.length}
//               </p>
//             </div>
//           </div>
//           {/* all Sold of Products section */}
//           <ProductCard products={soldProducts} sold={"sold-label"}/>
//           <div className="text-center">
//             <a href="/sold/telehandler" className="cbtn_dark mt-4">
//               View All Sold Items
//             </a>
//           </div>
//         </div>
//       </section>

//       <Suggestion />

//       {/* Tag Section */}
//       {/* <section className='tag_section py-5'>
//         <div className='container aos-init aos-animate'>
//           <h3 className="border_title txt_dark">
//             Tags
//           </h3>
//           <ul className='mt-4 ml-lg-5'>
//             <li><a href="/">CATERPILLAR 966 For Sale</a></li>
//             <li><a href="/">CATERPILLAR 966 For Sale</a></li>
//             <li><a href="/">CATERPILLAR 966 For Sale</a></li>
//             <li><a href="/">CATERPILLAR 966 For Sale</a></li>
//             <li><a href="/">CATERPILLAR 966 For Sale</a></li>
//             <li><a href="/">CATERPILLAR 966 For Sale</a></li>
//             <li><a href="/">CATERPILLAR 966 For Sale</a></li>
//             <li><a href="/">CATERPILLAR 966 For Sale</a></li>
//             <li><a href="/">CATERPILLAR 966 For Sale</a></li>
//             <li><a href="/">CATERPILLAR 966 For Sale</a></li>
//             <li><a href="/">CATERPILLAR 966 For Sale</a></li>
//           </ul>

//         </div>

//       </section> */}

//       {/* BLOG & NEWS section */}
//       {/* <Blog /> */}
//     </>
//   );
// };

// export default Telehandler;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import ListProductsSlice from "../../store/action/listProductsSlice";
import ListCategoriesBrandsSlice from "../../store/action/listCategories-BrandsSlice";
import Spinner from "../../Components/Spinner/spinner";
import { scrollToTop } from "../../functions/scrollToTop";
import Suggestion from "../../Components/Suggestion Form/suggestion";
import ProductCard from "../../Components/ProductCard/productCard";

const Telehandler = () => {
  const [products, setProducts] = useState([]);
  const [soldProducts, setSoldProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const dispatch = useDispatch();
  const productsList = useSelector((state) => state.productsList);

  const getAllProducts = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/products`,
        {
          params: {
            limit: 20,
            page: page,
            search: "",
            category: "",
            brand: "",
            model: "",
            year: "",
          },
        }
      );
      dispatch(ListProductsSlice(res.data));

      const availableProducts = res.data.filter(
        (product) => product.category === "Telehandler" && !product.sold
      );
      const soldProducts = res.data.filter(
        (product) => product.category === "Telehandler" && product.sold
      );

      setProducts((prevProducts) => [...prevProducts, ...availableProducts]);
      setSoldProducts((prevSoldProducts) => [
        ...prevSoldProducts,
        ...soldProducts,
      ]);
      setIsLoading(false);

      if (availableProducts.length === 0) {
        setHasMore(false);
      }
    } catch (error) {
      console.error("Failed to fetch products", error);
    }
  };

  const loadMore = () => {
    if (hasMore) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    getAllProducts();
  }, [page]);

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <>
      {/* banner section */}
      <section className="telehandler-banner">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 align-self-center">
              <div
                className="equipment_banner_slider carousel carousel-fade slide"
                id="heroCarousel"
                data-ride="carousel"
              >
                <div
                  id="carouselExample Autoplaying"
                  className="carousel slide"
                  data-bs-ride="carousel"
                >
                  <div className="carousel-inner">
                    <div className="carousel-item active" style={{}}>
                      <div className="carousel-container">
                        <h2 className="lg_text animate__animated animate__fadeInLeft animate__slow">
                          FIND YOUR NEXT
                          <span className="d-block">telehandler</span>
                          <span className="d-block">WITH GCES</span>
                        </h2>
                        <div className="divider  animate__animated animate__fadeInLeft animate__delay-0.5s"></div>
                        <h1
                          className="md_text aos-init aos-animate"
                          data-aos="fade-right"
                          data-aos-delay={600}
                          data-aos-duration={1500}
                          style={{ fontWeight: "normal" }}
                        >
                          Explore a variety of machine now
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="inventory_section py-4">
        <div className="container">
          <div className="title_with_sep text-center">
            <h3>USED Equipments</h3>
            <div className="divider m-auto"></div>
          </div>
          <div className="row mx-10 aos-init aos-animate">
            <div className="col-12 col-md-8 text-md-left text-center pb-3 px-10">
              <p className="sorting_text">Showing 1 - {products.length}</p>
            </div>
            <div className="col-12 col-md-4 text-md-right text-center pb-3 px-10">
              <ul className="sort_breadcrums">
                <li>
                  <a href="/">HOME</a>
                </li>
                <li>Telehandler</li>
              </ul>
            </div>
          </div>
          {isLoading ? (
            <div className="text-center my-4">
              <Spinner />
            </div>
          ) : (
            <ProductCard products={products} />
          )}
          {hasMore && (
            <div className="text-center">
              <span className="cbtn_dark mt-20 cursor" onClick={loadMore}>
                Load More
              </span>
            </div>
          )}
        </div>
      </section>

      <section className="inventory_section py-5">
        <div className="container">
          <div className="title_with_sep text-center">
            <h3>SOLD ITEMS</h3>
            <div className="divider m-auto"></div>
          </div>
          <div className="row mx-10">
            <div className="col-md-8 text-md-left text-center pb-3 px-10">
              <p className="sorting_text text-start">
                Showing 1 - {soldProducts.length}
              </p>
            </div>
          </div>
          <ProductCard products={soldProducts} sold={"sold-label"} />
          <div className="text-center">
            <a href="/sold/telehandler" className="cbtn_dark mt-4">
              View All Sold Items
            </a>
          </div>
        </div>
      </section>

      <Suggestion />
    </>
  );
};

export default Telehandler;
