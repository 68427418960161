import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import ListProductsSlice from "../../store/action/listProductsSlice";
import ListCategoriesBrandsSlice from "../../store/action/listCategories-BrandsSlice";
import Spinner from "../../Components/Spinner/spinner";
import { scrollToTop } from "../../functions/scrollToTop";
import Suggestion from "../../Components/Suggestion Form/suggestion";
import ProductCard from "../../Components/ProductCard/productCard";

const Parts = () => {
  const [products, setProducts] = useState([]);
  const [soldProducts, setSoldProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const dispatch = useDispatch();
  const productsList = useSelector((state) => state.productsList);

  const getAllProducts = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/products`, {
          params: {
            limit: 50,
            page: page,
            search: '',
            category: '',
            brand: '',
            model: '',
            year: ''
          }
        }
      );
      dispatch(ListProductsSlice(res.data));

      const availableProducts = res.data.filter(product => product.category === "Parts" && !product.sold);
      const soldProducts = res.data.filter(product => product.category === "Parts" && product.sold);

      setProducts((prevProducts) => [...prevProducts, ...availableProducts]);
      setSoldProducts((prevSoldProducts) => [...prevSoldProducts, ...soldProducts]);
      setIsLoading(false);

      if (availableProducts.length === 0) {
        setHasMore(false);
      }
    } catch (error) {
      console.error("Failed to fetch products", error);
    }
  };

  const loadMore = () => {
    if (hasMore) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    getAllProducts();
  }, [page]);

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <>
      <section className="parts-banner">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 align-self-center">
              <div className="carousel carousel-fade slide" id="heroCarousel" data-ride="carousel">
                <div id="carouselExampleAutoplaying" className="carousel slide" data-bs-ride="carousel">
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <div className="carousel-container">
                        <h2 className="lg_text animate__animated animate__fadeInLeft animate__slow">
                          EXPLORE WIDE
                          <span className="d-block">RANGE OF NEW &</span>
                          <span className="d-block"> USED ATTACHMENTS</span>
                        </h2>
                        <div className="divider animate__animated animate__fadeInLeft animate__delay-0.5s"></div>
                        <h1 className="md_text" data-aos="fade-right" data-aos-delay={600} data-aos-duration={1500}>
                          PARTS FOR SALE
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="inventory_section py-4">
        <div className="container">
          <div className="title_with_sep text-center">
            <h3>USED Equipments</h3>
            <div className="divider m-auto"></div>
          </div>
          <div className="row mx-10">
            <div className="col-12 col-md-8 text-md-left text-center pb-3 px-10">
              <p className="sorting_text">Showing 1 - {products.length}</p>
            </div>
            <div className="col-12 col-md-4 text-md-right text-center pb-3 px-10">
              <ul className="sort_breadcrums">
                <li>
                  <a href="/">HOME</a>
                </li>
                <li>PARTS</li>
              </ul>
            </div>
          </div>
          {isLoading ? (
            <div className="text-center my-4">
              <Spinner />
            </div>
          ) : (
            <ProductCard products={products} />
          )}
          {hasMore && (
            <div className="text-center">
              <span className="cbtn_dark mt-20 cursor" onClick={loadMore}>
                Load More
              </span>
            </div>
          )}
        </div>
      </section>

      <section className="inventory_section py-5">
        <div className="container">
          <div className="title_with_sep text-center">
            <h3>SOLD ITEMS</h3>
            <div className="divider m-auto"></div>
          </div>
          <div className="row mx-10">
            <div className="col-md-8 text-md-left text-center pb-3 px-10">
              <p className="sorting_text text-start">
                Showing 1 - {soldProducts.length}
              </p>
            </div>
          </div>
          <ProductCard products={soldProducts} sold={"sold-label"} />
          <div className="text-center">
            <a href="/sold/parts" className="cbtn_dark mt-4">
              View All Sold Items
            </a>
          </div>
        </div>
      </section>

      <Suggestion />
    </>
  );
};

export default Parts;
