import React from 'react';

const Blog = () => {
    return (
        <section className='new_arrival_section py-5 px-2' style={{ backgroundImage: "none" }}>
            <div className='container aos-init aos-animate ' >
                <div className='title_with_sep text-center'>
                    <h3>BLOG & NEWS</h3>
                    <div className="divider m-auto"></div>
                    {/* <a href="/" className="cbtn_dark mt-4 fw-semibold">Video Gallery</a> */}
                </div>
                <div className='an-latest-inventary-breadcrum aos-init aos-animate'>
                    <div>showing 1-3</div>
                    <div className='my-1'>
                        <span> HOME </span>
                        <span> |</span>
                        <span> Blogs</span>
                        <span> |</span>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4 col-sm-6 aos-init aos-animate">
                        <div className="an-blog-box">
                            <figure>
                                <img src="https://www.my-equipment.com/blog/wp-content/uploads/2024/02/Decarbonization-Of-Heavy-Equipment-For-A-Better-Tomorrow.png" alt="2003 CATERPILLAR 980GII" />
                            </figure>
                            <h3>Decarbonization Of Heavy Equipment For A Better Tomorrow</h3>
                            <p>February 27, 2024</p>
                            <a href="/" className="cbtn_dark mt-20 details">
                                <i className="bi bi-eye"></i> Read More
                            </a>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6 aos-init aos-animate">
                        <div className="an-blog-box">
                            <figure>
                                <img src="https://www.my-equipment.com/blog/wp-content/uploads/2024/02/Equipment-Safety-Guide-The-Significance-Of-Head-Protection-To-Know.png" alt="2003 CATERPILLAR 980GII" />
                            </figure>
                            <h3>Decarbonization Of Heavy Equipment For A Better Tomorrow</h3>
                            <p>February 27, 2024</p>
                            <a href="/" className="cbtn_dark mt-20 details">
                                <i className="bi bi-eye"></i> Read More
                            </a>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6 aos-init aos-animate">
                        <div className="an-blog-box">
                            <figure>
                                <img src="https://www.my-equipment.com/blog/wp-content/uploads/2024/02/Why-Do-Contractors-Need-To-Acquire-Craft-Training-In-2024.png" alt="2003 CATERPILLAR 980GII" />
                            </figure>
                            <h3>Decarbonization Of Heavy Equipment For A Better Tomorrow</h3>
                            <p>February 27, 2024</p>
                            <a href="/" className="cbtn_dark mt-20 details">
                                <i className="bi bi-eye"></i> Read More
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Blog;
