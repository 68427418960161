import React, { useEffect, useState } from "react";
import "./equipment.css";
import axios from "axios";
import { useDispatch } from "react-redux";
import ListProductsSlice from "../../store/action/listProductsSlice";
import Suggestion from "../../Components/Suggestion Form/suggestion";
import Spinner from "../../Components/Spinner/spinner";
import { scrollToTop } from "../../functions/scrollToTop";
import ProductCard from "../../Components/ProductCard/productCard";

const MiniExcavators = () => {
  const [products, setProducts] = useState([]);
  const [soldProducts, setSoldProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  const getAllProducts = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/products?limit=50&page=0&search=&category=&brand=&model&year=`
      );
      dispatch(ListProductsSlice(res.data));

      const miniExcavators = res.data.filter(product => product.category === "Mini Excavator");
      setProducts(miniExcavators.filter(product => !product.sold));
      setSoldProducts(miniExcavators.filter(product => product.sold));
    } catch (error) {
      console.error("Failed to fetch products:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllProducts();
    scrollToTop();
  }, [dispatch]);

  return (
    <>
      {/* banner section */}
      <section className="mini-excavators-banner">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 align-self-center">
              <div
                className="equipment_banner_slider carousel carousel-fade slide"
                id="heroCarousel"
                data-ride="carousel"
              >
                <div
                  id="carouselExampleAutoplaying"
                  className="carousel slide"
                  data-bs-ride="carousel"
                >
                  <div className="carousel-inner">
                    <div className="carousel-item active" style={{}}>
                      <div className="carousel-container">
                        <h2 className="lg_text animate__animated animate__fadeInLeft animate__slow">
                          FIND YOUR NEXT
                          <span className="d-block">mini excavators</span>
                          <span className="d-block">WITH GCES</span>
                        </h2>
                        <div className="divider animate__animated animate__fadeInLeft animate__delay-0.5s"></div>
                        <h1
                          className="md_text aos-init aos-animate"
                          data-aos="fade-right"
                          data-aos-delay={600}
                          data-aos-duration={1500}
                          style={{ fontWeight: "normal" }}
                        >
                          Explore a variety of machine now
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* all Equipment section */}
      <section
        className="inventory_section py-4"
        style={{ backgroundImage: "none" }}
      >
        <div className="container">
          <div className="title_with_sep text-center aos-init aos-animate">
            <h3>USED Equipments</h3>
            <div className="divider m-auto"></div>
          </div>
          <div className="row mx-10 aos-init aos-animate">
            <div className="col-12 col-md-8 text-md-left text-center pb-3 px-10">
              <p className="sorting_text">Showing 1 - {products.length}</p>
            </div>
            <div className="col-12 col-md-4 text-md-right text-center pb-3 px-10">
              <ul className="sort_breadcrums">
                <li>
                  <a href="/">HOME</a>
                </li>
                <li>Mini Excavator</li>
              </ul>
            </div>
          </div>

          {isLoading ? (
            <div className="text-center my-4">
              <Spinner />
            </div>
          ) : (
            <ProductCard products={products} />
          )}
        </div>
      </section>

      {/* Sold Item Section */}
      <section
        className="inventory_section py-5"
        style={{ backgroundImage: "none" }}
      >
        <div className="container">
          <div className="title_with_sep text-center aos-init aos-animate">
            <h3>SOLD ITEMS</h3>
            <div className="divider m-auto"></div>
          </div>
          <div className="row mx-10 aos-init aos-animate">
            <div className="col-md-8 text-md-left text-center pb-3 px-10">
              <p className="sorting_text text-start">
                Showing 1 - {soldProducts.length}
              </p>
            </div>
          </div>
          {/* all Sold of Products section */}
          <ProductCard products={soldProducts} sold={"sold-label"} />
          <div className="text-center">
            <a href="/sold/mini-excavator" className="cbtn_dark mt-4">
              View All Sold Items
            </a>
          </div>
        </div>
      </section>

      <Suggestion />

      {/* BLOG & NEWS section */}
      {/* <Blog /> */}
    </>
  );
};

export default MiniExcavators;
