import React from 'react';
import SoldEquipment from '../../Components/Sold Equipment/soldEquipment';

const SoldTrackLoader = () => {
    return (
        <div>
            <SoldEquipment cat="Track Loader" name="track-loader"/>
        </div>
    );
}

export default SoldTrackLoader;
