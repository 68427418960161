import React from 'react';

const Services = () => {
    return (

        // old design in services
        // <section className='our_service py-5'>
        //         <div className='container px-4'>
        //             <div className='title_with_sep text-center aos-init aos-animate'>
        //                 <h3>OUR SERVICES</h3>
        //                 <div className="divider m-auto"></div>

        //             </div>
        //             <div className="row">
        //                 <div className='col-lg-4 col-md-6 px-1 aos-init aos-animate'>
        //                     <div className='service_box'>
        //                         <div className="s_img">
        //                             <img src="./images/shipping.jpg" alt="Dismantling" className="img-fluid w-100 lazyloaded " />
        //                         </div>
        //                         <h3>
        //                             SHIPPING
        //                         </h3>
        //                         <div className='service_desc'>
        //                             <p>
        //                                 <span>
        //                                     Contact the GCES team right away if you need a fast, dependable, and safe way to move construction equipment throughout the world. We provide  ...
        //                                 </span>
        //                             </p>
        //                             <a href="/dismantling">Read More...</a>

        //                         </div>

        //                     </div>

        //                 </div>
        //                 <div className='col-lg-4 col-md-6 px-1 aos-init aos-animate'>
        //                     <div className='service_box'>
        //                         <div className="s_img">
        //                             <img src="./images/inspections.jpg" alt="Dismantling" className="img-fluid w-100 lazyloaded h-50" />
        //                         </div>
        //                         <h3>
        //                             INSPECTION
        //                         </h3>
        //                         <div className='service_desc'>
        //                             <p>
        //                                 <span>
        //                                     When it comes to construction equipment evaluation, we want you to feel confident in the condition of your equipment. We will supply all essential  ...
        //                                 </span>
        //                             </p>
        //                             <a href="/dismantling">Read More...</a>

        //                         </div>

        //                     </div>

        //                 </div>
        //                 <div className='col-lg-4 col-md-6 px-1 aos-init aos-animate'>
        //                     <div className='service_box'>
        //                         <div className="s_img">
        //                             <img src="./images/transport.jpg" alt="Dismantling" className="img-fluid w-100 lazyloaded h-50" />
        //                         </div>
        //                         <h3>
        //                             TRASPORTION
        //                         </h3>
        //                         <div className='service_desc'>
        //                             <p>
        //                                 <span>
        //                                     Our used construction equipment could be sent to any area in the United States. We provide comprehensive transportation for construction, agricultural ...
        //                                 </span>
        //                             </p>
        //                             <a href="/dismantling">Read More...</a>

        //                         </div>

        //                     </div>

        //                 </div>
        //                 {/* <div className='col-lg-3 col-md-6 px-1 aos-init aos-animate'>
        //                     <div className='service_box'>
        //                         <div className="s_img">
        //                             <img src="https://www.my-equipment.com/newsite/images/service_1.png" alt="Dismantling" className="img-fluid w-100 lazyloaded" />
        //                         </div>
        //                         <h3>
        //                             Dismantling
        //                         </h3>
        //                         <div className='service_desc'>
        //                             <p>
        //                                 <span>
        //                                     Whether you need to move between construction jobs, or you are having problems with a faulty machine and need ...
        //                                 </span>
        //                             </p>
        //                             <a href="/dismantling">Read More...</a>

        //                         </div>

        //                     </div>

        //                 </div> */}
        //             </div>

        //         </div>

        //     </section>


        // new design
        <section className='bg_dark pb-lg-5 pb-4'>
            <div className="container">
                <h3 className='border_title aos-init aos-animate'>
                    More Services
                </h3>
                <div className="row pt-3">
                    <div className='col-md-6 col-lg-4 aos-init aos-animate'>
                        <div className=''>
                            <a href="/transport">
                                <img src="./images/transport2.jpg" className="w-100 lazyloaded img-fluid" alt="transport"/>
                            </a>
                            <div className='content_padding'>
                                <h3 className="font24_extrabold  text-uppercase">TRASPORTION</h3>
                                <p className="text-white text-left font_12">Our used construction equipment could be sent to any area in the United States. We provide comprehensive transportation for construction ...</p>
                                <a href="/transport">Read More...</a>
                            </div>
                        </div>

                    </div>
                    <div className='col-md-6 col-lg-4 aos-init aos-animate'>
                        <div className=''>
                            <a href="/inspections">
                                <img src="./images/inspections2.jpg" className="w-100 lazyloaded img-fluid" alt="inspections"/>
                            </a>
                            <div className='content_padding'>
                                <h3 className="font24_extrabold text-uppercase">INSPECTIONS</h3>
                                <p className="text-white text-left font_12">When it comes to construction equipment evaluation, we want you to feel confident in the condition of your equipment. We will  ...</p>
                                <a href="/inspections">Read More...</a>
                            </div>
                        </div>

                    </div>
                    <div className='col-md-6 col-lg-4 aos-init aos-animate'>
                        <div className=''>
                            <a href="/shipping">
                                <img src="./images/shipping2.jpg" alt="shipping" className="w-100 lazyloaded img-fluid" />
                            </a>
                            <div className='content_padding'>
                                <h3 className="font24_extrabold text-uppercase">SHIPPING</h3>
                                <p className="text-white text-left font_12">Contact the GCES team right away if you need a fast, dependable, and safe way to move construction equipment throughout the world ...</p>
                                <a href="/shipping">Read More...</a>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </section>
    );
}

export default Services;
