import React from 'react';

const ReturnPolicy = () => {
    return (
        <main>
            <div className='single_equipment py-lg-5 py-4'>
                <div className="container">
                    <div className='navigation_border mb-lg-4 mb-3 aos-init aos-animate'>
                        <div className="row">
                            <div className='col-md-12 text-md-right text-center pb-lg-3 d-none d-sm-block'>
                                <ul className="sort_breadcrums">
                                    <li><a href="/">Home</a></li>
                                    <li>RETURN POLICY</li>
                                </ul>


                            </div>
                        </div>
                    </div>
                    <div className='content_area'>
                        <h3 className='mini_border_title pt-2 aos-init aos-animate'>
                            Return Policy
                        </h3>
                        <p className='aos-init aos-animate'>
                            MY Equipment does not accept returns.
                        </p>
                        <h3 className='mini_border_title pt-2 aos-init aos-animate'>
                            Buy Machinery from MY Equipment with Complete Confidence!
                        </h3>
                        <p className='aos-init aos-animate'>
                            Machinery sold by MY Equipment LLC is sold as is, and we do not accept returns. MY Equipment ensures the condition of the equipment being sold, and the equipment that you receive will be in the exact condition that is described. If the equipment you received is not exactly as it was described in the report, contact our team immediately.
                        </p>
                        <p className='aos-init aos-animate'>
                            Only claims received within one business day of receiving the item will be entertained. If the claim is valid, it will be handled in a way that is beneficial to both parties. MY Equipment provides any available inspection reports whenever necessary or as requested.

                        </p>
                        <h3 className='mini_border_title pt-2 aos-init aos-animate'>
                            Liability
                        </h3>
                        <p className='aos-init aos-animate'>
                            MY Equipment will not be liable for any loss or damage incurred either directly or indirectly. The buyer should understand that MY Equipment is neither the manufacturer nor the agent of the manufacturer of the equipment and parts being sold.
                        </p>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default ReturnPolicy;
