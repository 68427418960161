import axios from 'axios';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ListCategoriesBrandsSlice from '../../store/action/listCategories-BrandsSlice';
import { useNavigate } from 'react-router-dom';

const Brands = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const brandsAndCats = useSelector(state => state.categoriesBrands)

    const getAllBrandsAndCat = async () => {
        if (!brandsAndCats) {
            const res = await axios.get(`${process.env.REACT_APP_SERVER_URL}/products/categories-brands`);
            dispatch(ListCategoriesBrandsSlice(res.data))
            // setBrands(res.data.);
            // setCategories(res.data.categories);
        }
    }

    useEffect(() => {
        // getAllBrandsAndCat();
    }, [dispatch]);
    return (
        <section className='brands_logos py-5'>
            <div className='container aos-init aos-animate'>
                <h3 className='border_title-brands txt_dark'>
                    Our Brands
                </h3>
                <ul className="mt-3 ml-lg-5 list-inline">
                    {brandsAndCats && brandsAndCats.brands.map((brand, _i) => {
                        return (
                            <li key={_i} className='mt-2'><a className='cursor' href={`${brand.name.toLowerCase().replace(/\s+/g, '-')}`} >
                                <img src={`${process.env.REACT_APP_SERVER_URL}/${brand.image}`} alt={brand.name} width={70} height={38} className=" lazyloaded mx-2" /></a>
                            </li>
                        )
                        // return (
                        //     <li className='mt-2'><a href="/caterpillar">
                        //         <img src={`${process.env.REACT_APP_SERVER_URL}/${brand.image}`} alt={brand.name} width={100} height={45} className=" lazyloaded mx-2" /></a>
                        //     </li>
                        // )
                    })}
                </ul>

            </div>

        </section>
    );
}

export default Brands;
