const initialValue = {
    productsList: "",
    product:"",
    categoriesBrands:"",
    detailedSearch:"",
    stockList:"",
}

export default function allReducer(state = initialValue, action) {

    switch (action.type) {
        case "GET_PRODUCTSLIST":
            return { ...state, productsList: action.payload };
        case "GET_DETAILEDSEARCH":
            return { ...state, detailedSearch: action.payload };
        case "GET_CATEGORIESBRANDSLIST":
            return { ...state, categoriesBrands: action.payload };
        case "GET_STOCKLIST":
            return { ...state, stockList: action.payload };
        case "GET_PRODUCT":
            return { ...state, product: action.payload };
        default:
            return state
    }
}