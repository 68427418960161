import React, { useEffect, useState } from "react";
import "./details.css";
import MultiSlider from "../../../Components/Multi Slider/multiSlider";
import Blog from "../../../Components/Blog/blog";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Product from "../../../store/action/product";

import ImageGallery from "react-image-gallery";
import { handleAlert } from "../../../functions/handleAlert";
import "react-image-gallery/styles/css/image-gallery.css";
import Spinner from "../../../Components/Spinner/spinner";
import { scrollToTop } from "../../../functions/scrollToTop";

const Details = () => {
  const dispatch = useDispatch();
  const product = useSelector((state) => state.product);
  const [imageGallery, setImageGalery] = useState([]);
  const [showButton, setShowButton] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [brand, setBrand] = useState();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");

  const getProduct = async () => {
    if (!product) {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/products/${id}`
      );
      dispatch(Product(res.data.productData));
      setBrand(res.data.brandData);
      setIsLoading(true);
      const images = res.data.productData.images.map((img) => ({
        original: `${process.env.REACT_APP_SERVER_URL}/${img}`,
        thumbnail: `${process.env.REACT_APP_SERVER_URL}/${img}`,
      }));
      setImageGalery(images);
      // console.log(res.data.brandData);
    }
  };

  const sendRequest = async (event) => {
    setShowButton(true);
    event.preventDefault();
    let name = event.target.name.value;
    let email = event.target.email.value;
    let phone = event.target.phone.value;
    let message = event.target.message.value;
    console.log(name, email, phone, message, id);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/order`,
        {
          email: email,
          name: name,
          phone: phone,
          message: message,
          product: id,
          brand: "Caterpillar",
          equipment: "equipmentos",
          country: "Egypt",
          company: "cater",
          category: "offer",
        }
      );
      handleAlert(res.data.message, "success");
      // console.log(res.data);
      setShowButton(false);
    } catch (error) {
      handleAlert(error.response.data.message, "error");
    }
  };

  // console.log(id);
  useEffect(() => {
    getProduct();
    scrollToTop();
  }, [dispatch]);

  return (
    <>
      <section className="single_equipment py-lg-5 py-4">
        <div className="container">
          <div className="navigation_border mb-lg-4 mb-3 aos-init aos-animate">
            <div className="row">
              <div className="col-md-4 text-md-left text-center pb-lg-3 pb-2">
                <div className="navigation_btn"></div>
              </div>
              <div className="col-md-8 text-md-right text-center pb-lg-3 mobile_none">
                <ul className="sort_breadcrums">
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <a className="cursor">{product && product.category}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {isLoading ? (
            <div className="row">
              <div className="col-lg-7 mb-3">
                <div className="row">
                  <div className="col-md-8 aos-init aos-animate">
                    <h1 className="single_eq_title pb-lg-3 pb-2">
                      {product && product.name}
                    </h1>
                    <div className="divider mb-lg-3 mb-2"></div>
                  </div>
                  <div className="col-md-4 aos-init aos-animate">
                    <p className="single_eq_price text-md-right">
                     $ {product && product.price === 0
                          ? "REQUEST FOR PRICE"
                          : product.price}
                    </p>
                  </div>
                </div>
                <ul className="single_eq_list mt-1 mb-2 aos-init aos-animate">
                  <li>
                    <i className="bi bi-calendar2-event"></i> &nbsp;
                    <b>Year</b> : {product && product.year}
                  </li>
                  <li>
                    <i className="bi bi-clock"></i> &nbsp;
                    <b>Hours</b> : {product && product.hours}
                  </li>
                  <li>
                    <i className="bi bi-list-ul"></i> &nbsp;
                    <b>Model</b> : {product && product.model}
                  </li>
                  <li>
                    <i className="bi bi-tag-fill"></i> &nbsp;
                    <b>Serial</b> : {product && product.serial}
                  </li>
                  <li>
                    <i className="bi bi-geo-alt-fill"></i> &nbsp;
                    <b>Location</b> : {product && product.location}
                  </li>
                </ul>
                <div
                  id="single-product-carousel"
                  className="single_eq_slider carousel slide mb-lg-4 mb-3 aos-init aos-animate"
                >
                  {/* slider */}
                  {/* <div className="position-relative">
                                    <div id="carouselExampleIndicators" className="carousel slide">
                                        <div className="carousel-indicators">
                                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={0} className="active" aria-current="true" aria-label="Slide 1" />
                                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={1} aria-label="Slide 2" />
                                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={2} aria-label="Slide 3" />
                                        </div>
                                        <div className="carousel-inner">
                                            <div className="carousel-item active">
                                                <img src={`${process.env.REACT_APP_SERVER_URL}/${product && product.images[0]}`} className="d-block w-100" alt="..." />
                                            </div>
                                            {product && product.images.map((img, index) => {
                                                return (
                                                    <div className="carousel-item " key={index}>
                                                        <img src={`${process.env.REACT_APP_SERVER_URL}/${img}`} className="d-block w-100" alt="..." />
                                                    </div>
                                                )
                                            })}


                                            <div className="carousel-item">
                                                <img src="https://cdn1.my-equipment.com/imgjpg/750x540/products/_2665_3.jpg" className="d-block w-100" alt="..." />
                                            </div>
                                        </div>
                                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                                            <span className="carousel-control-prev-icon" aria-hidden="true" />
                                            <span className="visually-hidden">Previous</span>
                                        </button>
                                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                                            <span className="carousel-control-next-icon" aria-hidden="true" />
                                            <span className="visually-hidden">Next</span>
                                        </button>
                                    </div>
                                </div> */}
                  {/* thumbnails */}
                  {/* <ol className='row mx-10 detail_custom_thumbnail p-0'>
                                    {product && product.images.map((img, index) => {
                                        return (
                                            <li key={index} className='col-md-2 mt-2 col-sm-3 col-xs-3 px-10 thumbnailvideostop'>
                                                <a href="">
                                                    <img src={`${process.env.REACT_APP_SERVER_URL}/${img}`} className="img-fluid lazyloaded" alt="Caterpillar 950G BAA00298" />

                                                </a>
                                            </li>
                                        )
                                    })}
                                </ol> */}
                  <div className="my-gallery-container">
                    <ImageGallery items={imageGallery} />
                  </div>
                  {/* <li className="thumbitems col-md-2 mt-2 col-sm-3 col-xs-3 px-10    ">
                                        <a id="carousel-selector-0" className="selected" data-slide-to={1} data-target="#single-product-carousel">
                                            <img src="https://cdn1.my-equipment.com/imgjpg/116x68/products/caterpillar-950g-baa00298_2334_16.jpg" data-src="https://cdn1.my-equipment.com/imgjpg/116x68/products/caterpillar-950g-baa00298_2334_16.jpg" className="img-fluid lazyloaded" alt="Caterpillar 950G BAA00298" />
                                        </a>
                                    </li>
                                    <li className="thumbitems col-md-2 mt-2 col-sm-3 col-xs-3 px-10  ">
                                        <a id="carousel-selector-0" data-slide-to={2} data-target="#single-product-carousel">
                                            <img src="https://cdn1.my-equipment.com/imgjpg/116x68/products/caterpillar-950g-baa00298_2334_17.jpg" data-src="https://cdn1.my-equipment.com/imgjpg/116x68/products/caterpillar-950g-baa00298_2334_17.jpg" className="img-fluid lazyloaded" alt="Caterpillar 950G BAA00298" />
                                        </a>
                                    </li>
                                    <li className="thumbitems col-md-2 mt-2 col-sm-3 col-xs-3 px-10  ">
                                        <a id="carousel-selector-0" data-slide-to={3} data-target="#single-product-carousel">
                                            <img src="https://cdn1.my-equipment.com/imgjpg/116x68/products/caterpillar-950g-baa00298_2334_19.png" data-src="https://cdn1.my-equipment.com/imgjpg/116x68/products/caterpillar-950g-baa00298_2334_19.png" className="img-fluid lazyloaded" alt="Caterpillar 950G BAA00298" />
                                        </a>
                                    </li>
                                    <li className="thumbitems col-md-2 mt-2 col-sm-3 col-xs-3 px-10  ">
                                        <a id="carousel-selector-0" data-slide-to={4} data-target="#single-product-carousel">
                                            <img src="https://cdn1.my-equipment.com/imgjpg/116x68/products/caterpillar-950g-baa00298_2334_18.png" data-src="https://cdn1.my-equipment.com/imgjpg/116x68/products/caterpillar-950g-baa00298_2334_18.png" className="img-fluid lazyloaded" alt="Caterpillar 950G BAA00298" />
                                        </a>
                                    </li>
                                    <li id="morephotos" className="thumbitems col-md-2 mt-2 col-sm-3 col-xs-3 px-10 morephotos">
                                        <img src="https://cdn.equipmentanywhere.com/p/15140519/120x91/611e0431b66b2416315265631431.jpg" width={116} height={68} alt />
                                        <span className="moreoverlay">
                                            More Photos
                                        </span>
                                    </li> */}
                </div>
                <div className="single_eq_content aos-init aos-animate">
                  <div className="pro_desc mb-lg-5 mb-4">
                    <h3>GENERAL</h3>
                    <div className="detail__specs-wrapper">
                      <div className="detail__specs-label"> YEAR </div>
                      <div className="detail__specs-value">
                        {product && product.year}
                      </div>
                      <div className="detail__specs-label">brand</div>
                      <div className="detail__specs-value">
                        {product && product.brand}
                      </div>
                      <div className="detail__specs-label">hours</div>
                      <div className="detail__specs-value">
                        {product && product.hours}
                      </div>
                      <div className="detail__specs-label">model</div>
                      <div className="detail__specs-value">
                        {product && product.model}
                      </div>
                      <div className="detail__specs-label">price</div>
                      <div className="detail__specs-value">
                        ${" "}
                        {product && product.price === 0
                          ? "REQUEST FOR PRICE"
                          : product.price}
                      </div>
                      <div className="detail__specs-label">Serial</div>
                      <div className="detail__specs-value">
                        {product && product.serial}
                      </div>
                      <div className="detail__specs-label">Description</div>
                      <div className="detail__specs-value">
                        {product && product.description}
                      </div>
                      {/* <div className="detail__specs-label">Description</div>
                                        <div className="detail__specs-value">{product && product.year}</div> */}
                    </div>
                    <h3 className="my-2">ATTACHMENTS</h3>
                    <div className="detail__specs-wrapper">
                      <div className="detail__specs-label"> Cleaner Bar </div>
                      <div className="detail__specs-value">Yes</div>
                    </div>
                    <h3 className="my-2">EXTERIOR</h3>
                    <div className="detail__specs-wrapper">
                      <div className="detail__specs-label"> ROPS </div>
                      <div className="detail__specs-value">No</div>
                    </div>
                  </div>
                  {/* <div className='more_option aos-init aos-animate'>
                                    <h3>MORE OPTIONS</h3>
                                    <a href="">
                                        <i className="bi bi-file-earmark-pdf"></i> INSPECTION Report
                                    </a>
                                    <a href="">
                                        <i className="bi bi-envelope"></i>  EMAIL TO FRIEND
                                    </a>
                                    <a href="">
                                        <i className="bi bi-image"></i>  View All Photos
                                    </a>
                                    <a href="">
                                        <i className="bi bi-download"></i><span>Download Pictures</span>
                                    </a>
                                    <a href="">
                                        <i className="bi bi-eye-fill"></i><span>View All Wheel Loaders</span>
                                    </a>
                                    <a href="">
                                        <i className="bi bi-whatsapp"></i><span> Whatsapp</span>
                                    </a>


                                </div> */}
                </div>
              </div>
              <div className="col-lg-5 mb-3">
                <div className="brand_logo aos-init aos-animate">
                  {brand && (
                    <img
                      src={`${process.env.REACT_APP_SERVER_URL}/${brand.image}`}
                      alt={brand.name}
                      className="brand-img  lazyloaded"
                      width={107}
                      height={53}
                    />
                  )}
                </div>
                <div className="sidebar_form aos-init aos-animate">
                  <h3>Make an Offer</h3>
                  <form onSubmit={sendRequest}>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      placeholder="Your Name"
                      required
                    />
                    <input
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Your Email"
                      required
                    />
                    <input
                      type="text"
                      name="phone"
                      id="phone"
                      placeholder="Your Number"
                      required
                    />
                    <textarea
                      id="message"
                      name="message"
                      placeholder="Type your message here"
                      required="required"
                      defaultValue={""}
                    />
                    {!showButton ? (
                      <button type="submit" id="send-msg">
                        <i className="bi bi-send-fill" /> Send Message
                      </button>
                    ) : (
                      <button type="submit">
                        <div className="spinner-border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </button>
                    )}
                  </form>
                </div>
                <div className="detail__contact-info">
                  <div className="MuiBox-root wwwetiglobalcom-w4b0yk">
                    <div className="dealer-contact dealer-contact_rental">
                      <div className="dealer-contact__header">
                        <h2 className="wwwetiglobalcom-1v2zh90">
                          Rental Contact Information
                        </h2>
                      </div>
                      <div className="dealer-contact__details">
                        <div className="dealer-contact__details_left-col">
                          <div className="dealer-contact__branch-name">
                            <p className="MuiTypography-root MuiTypography-body2 dealer-contact__branch-name wwwetiglobalcom-di8bwg">
                              GCES Equipment
                              {/* &amp; Trucks Inc */}
                            </p>
                          </div>
                          <div className="dealer-contact__name">
                            <p className="MuiTypography-root MuiTypography-body2 dealer-contact__name wwwetiglobalcom-di8bwg">
                              <span className="dealer-contact__label">
                                <span>Contact </span>:{" "}
                              </span>
                              {/* Jeff Fridrich */}
                            </p>
                          </div>
                          <div className="dealer-contact__location">
                            <p className="MuiTypography-root MuiTypography-body2 dealer-contact__location wwwetiglobalcom-di8bwg">
                              {/* Loveland, Colorado 80537 */}
                            </p>
                          </div>
                        </div>
                        <div className="dealer-contact__details_right-col">
                          <div className="sb_contact_list aos-init aos-animate">
                            <ul>
                              <li>
                                <i className="bi bi-geo-alt-fill"></i>
                                800 S Brookhurst St, Suit 3-B,{" "}
                                <span className="d-lg-block">
                                  Anaheim, CA 92804, USA.
                                </span>
                              </li>
                              <li className="d-flex align-items-center">
                                <i className="bi bi-whatsapp "></i>
                                <a href="" className="wa-triggered">
                                  +1 (657) 347-6660
                                </a>
                              </li>
                              <li>
                                <i className="bi bi-telephone-fill"></i>
                                <a href="/" className="">
                                  +1 (657) 347-6660
                                </a>
                              </li>
                              <li>
                                <i className="bi bi-envelope"></i>
                                <a
                                  href="mailto:advertising@gcesequipment.com"
                                  className=""
                                >
                                  advertising@gcesequipment.com
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="text-center my-4">
              <Spinner />
            </div>
          )}
        </div>
      </section>

      {/* Ads banner */}
      {/* <section className='container py-5'>
                <div className='Ads_banner' style={{ background: "url(https://www.my-equipment.com/newsite/images/small-banner/wheel-loaders.jpg?v=240129084719)" }}>
                    <div className='row'>
                        <div className='col-lg-8'>
                            <h3 className='aos-init aos-animate'>
                                Great Machines From Leading Manufacturers
                            </h3>
                            <a href="/" className='btn_banner aos-init aos-animate'>
                                <i className="bi bi-receipt-cutoff main-color fs-4"></i>
                                &nbsp; View All Inventory
                            </a>
                            <a href="/" className='btn_banner mr-md-1 aos-init aos-animate'>
                                <i className="bi bi-chat-left-quote main-color fs-4 "></i>
                                &nbsp; Get A Quote
                            </a>
                        </div>
                    </div>

                </div>
            </section> */}

      {/* <Blog /> */}

      {/*  Similar Products  */}
      {/* <section className='inventory_section py-5 aos-init aos-animate' style={{ background: "none" }}>
                <div className="container1">
                    <div className='title_with_sep text-center'>
                        <h3>Similar Listing</h3>
                        <div className="divider m-auto"></div>
                        <a href="" className='cbtn_dark mt-4'>View all Wheel Loaders</a>
                        <section className="w-75 m-auto">
                            <div className="w-75 m-auto">
                                <div className="text-nowrap overflow-hidden cursor">
                                    <div
                                        className="d-flex"
                                        style={{ overflowX: "auto", whiteSpace: "nowrap" }}
                                    >
                                        <div

                                            className="gallary-card-review position-relative cursor me-2 m-0"
                                            style={{
                                                width: "225px",
                                                height: "150px",
                                                flex: "0 0 225px",
                                            }}
                                        >

                                            <img
                                                className="img-review d-inline-block position-absolute photos-tumbnail lazyloaded"
                                                src="https://cdn1.my-equipment.com/imgjpg/290x212/products/parts-attachments-cat-988b-blade-bucket-308126006wheel-loaders-cat-988b-blade-bucket-01.png"
                                                width="225"
                                                height="150"
                                                alt="gallery"
                                            />

                                        </div>
                                        <div

                                            className="gallary-card-review position-relative cursor me-2 m-0"
                                            style={{
                                                width: "225px",
                                                height: "150px",
                                                flex: "0 0 225px",
                                            }}
                                        >

                                            <img
                                                className="img-review d-inline-block position-absolute photos-tumbnail lazyloaded"
                                                src="https://cdn1.my-equipment.com/imgjpg/290x212/products/parts-attachments-cat-988b-blade-bucket-308126006wheel-loaders-cat-988b-blade-bucket-01.png"
                                                width="225"
                                                height="150"
                                                alt="gallery"
                                            />
                                        </div>
                                        <div

                                            className="gallary-card-review position-relative cursor me-2 m-0"
                                            style={{
                                                width: "225px",
                                                height: "150px",
                                                flex: "0 0 225px",
                                            }}
                                        >

                                            <img
                                                className="img-review d-inline-block position-absolute photos-tumbnail lazyloaded"
                                                src="https://cdn1.my-equipment.com/imgjpg/290x212/products/parts-attachments-cat-988b-blade-bucket-308126006wheel-loaders-cat-988b-blade-bucket-01.png"
                                                width="225"
                                                height="150"
                                                alt="gallery"
                                            />
                                        </div>
                                        <div

                                            className="gallary-card-review position-relative cursor me-2 m-0"
                                            style={{
                                                width: "225px",
                                                height: "150px",
                                                flex: "0 0 225px",
                                            }}
                                        >

                                            <img
                                                className="img-review d-inline-block position-absolute photos-tumbnail lazyloaded"
                                                src="https://cdn1.my-equipment.com/imgjpg/290x212/products/parts-attachments-cat-988b-blade-bucket-308126006wheel-loaders-cat-988b-blade-bucket-01.png"
                                                width="225"
                                                height="150"
                                                alt="gallery"
                                            />
                                        </div>
                                        <div

                                            className="gallary-card-review position-relative cursor me-2 m-0"
                                            style={{
                                                width: "225px",
                                                height: "150px",
                                                flex: "0 0 225px",
                                            }}
                                        >

                                            <img
                                                className="img-review d-inline-block position-absolute photos-tumbnail lazyloaded"
                                                src="https://cdn1.my-equipment.com/imgjpg/290x212/products/parts-attachments-cat-988b-blade-bucket-308126006wheel-loaders-cat-988b-blade-bucket-01.png"
                                                width="225"
                                                height="150"
                                                alt="gallery"
                                            />
                                        </div>
                                        <div

                                            className="gallary-card-review position-relative cursor me-2 m-0"
                                            style={{
                                                width: "225px",
                                                height: "150px",
                                                flex: "0 0 225px",
                                            }}
                                        >

                                            <img
                                                className="img-review d-inline-block position-absolute photos-tumbnail lazyloaded"
                                                src="https://cdn1.my-equipment.com/imgjpg/290x212/products/parts-attachments-cat-988b-blade-bucket-308126006wheel-loaders-cat-988b-blade-bucket-01.png"
                                                width="225"
                                                height="150"
                                                alt="gallery"
                                            />
                                        </div>
                                        <div

                                            className="gallary-card-review position-relative cursor me-2 m-0"
                                            style={{
                                                width: "225px",
                                                height: "150px",
                                                flex: "0 0 225px",
                                            }}
                                        >

                                            <img
                                                className="img-review d-inline-block position-absolute photos-tumbnail lazyloaded"
                                                src="https://cdn1.my-equipment.com/imgjpg/290x212/products/parts-attachments-cat-988b-blade-bucket-308126006wheel-loaders-cat-988b-blade-bucket-01.png"
                                                width="225"
                                                height="150"
                                                alt="gallery"
                                            />
                                        </div>
                                        <div

                                            className="gallary-card-review position-relative cursor me-2 m-0"
                                            style={{
                                                width: "225px",
                                                height: "150px",
                                                flex: "0 0 225px",
                                            }}
                                        >

                                            <img
                                                className="img-review d-inline-block position-absolute photos-tumbnail lazyloaded"
                                                src="https://cdn1.my-equipment.com/imgjpg/290x212/products/parts-attachments-cat-988b-blade-bucket-308126006wheel-loaders-cat-988b-blade-bucket-01.png"
                                                width="225"
                                                height="150"
                                                alt="gallery"
                                            />
                                        </div>
                                        <div

                                            className="gallary-card-review position-relative cursor me-2 m-0"
                                            style={{
                                                width: "225px",
                                                height: "150px",
                                                flex: "0 0 225px",
                                            }}
                                        >

                                            <img
                                                className="img-review d-inline-block position-absolute photos-tumbnail lazyloaded"
                                                src="https://cdn1.my-equipment.com/imgjpg/290x212/products/parts-attachments-cat-988b-blade-bucket-308126006wheel-loaders-cat-988b-blade-bucket-01.png"
                                                width="225"
                                                height="150"
                                                alt="gallery"
                                            />
                                        </div>
                                        <div

                                            className="gallary-card-review position-relative cursor me-2 m-0"
                                            style={{
                                                width: "225px",
                                                height: "150px",
                                                flex: "0 0 225px",
                                            }}
                                        >

                                            <img
                                                className="img-review d-inline-block position-absolute photos-tumbnail lazyloaded"
                                                src="https://cdn1.my-equipment.com/imgjpg/290x212/products/parts-attachments-cat-988b-blade-bucket-308126006wheel-loaders-cat-988b-blade-bucket-01.png"
                                                width="225"
                                                height="150"
                                                alt="gallery"
                                            />
                                        </div>
                                        <div

                                            className="gallary-card-review position-relative cursor me-2 m-0"
                                            style={{
                                                width: "225px",
                                                height: "150px",
                                                flex: "0 0 225px",
                                            }}
                                        >

                                            <img
                                                className="img-review d-inline-block position-absolute photos-tumbnail lazyloaded"
                                                src="https://cdn1.my-equipment.com/imgjpg/290x212/products/parts-attachments-cat-988b-blade-bucket-308126006wheel-loaders-cat-988b-blade-bucket-01.png"
                                                width="225"
                                                height="150"
                                                alt="gallery"
                                            />
                                        </div>
                                        <div

                                            className="gallary-card-review position-relative cursor me-2 m-0"
                                            style={{
                                                width: "225px",
                                                height: "150px",
                                                flex: "0 0 225px",
                                            }}
                                        >

                                            <img
                                                className="img-review d-inline-block position-absolute photos-tumbnail lazyloaded"
                                                src="https://cdn1.my-equipment.com/imgjpg/290x212/products/parts-attachments-cat-988b-blade-bucket-308126006wheel-loaders-cat-988b-blade-bucket-01.png"
                                                width="225"
                                                height="150"
                                                alt="gallery"
                                            />
                                        </div>
                                        <div

                                            className="gallary-card-review position-relative cursor me-2 m-0"
                                            style={{
                                                width: "225px",
                                                height: "150px",
                                                flex: "0 0 225px",
                                            }}
                                        >

                                            <img
                                                className="img-review d-inline-block position-absolute photos-tumbnail lazyloaded"
                                                src="https://cdn1.my-equipment.com/imgjpg/290x212/products/cat-988-balderson-blade-bucket-1280371712wheel-loaders-cat-988b-blade-bucket-01.png"
                                                width="225"
                                                height="150"
                                                alt="gallery"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>

            </section> */}
    </>
  );
};

export default Details;
