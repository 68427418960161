import React, { useEffect, useState } from "react";
import "./stockList.css";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import ListProductsSlice from "../../store/action/listProductsSlice";
import { useNavigate } from "react-router-dom";
import { scrollToTop } from "../../functions/scrollToTop";
import Spinner from "../../Components/Spinner/spinner";
import ProductCard from "../../Components/ProductCard/productCard";

const StockList = () => {
  const [page, setPage] = useState(1);
  const [stockList, setStockList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMoreProducts, setHasMoreProducts] = useState(true);

  const products = useSelector((state) => state.productsList);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fetchProducts = async (page, limit = 10) => {
    setIsLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/products`, {
          params: {
            limit,
            page,
            search: "",
            category: "",
            brand: "",
            model: "",
            year: "",
            sold: false
          }
        }
      );
      return res.data;
    } catch (error) {
      console.error("Failed to fetch products", error);
      return [];
    } finally {
      setIsLoading(false);
    }
  };

  const getAllProducts = async () => {
    if (!products) {
      const fetchedProducts = await fetchProducts(0, 10);
      dispatch(ListProductsSlice(fetchedProducts));
      setStockList(fetchedProducts);
    } else {
      setStockList(products);
    }
  };

  const loadMoreProducts = async () => {
    const newProducts = await fetchProducts(page, 5);
    setStockList((prevStockList) => [...prevStockList, ...newProducts]);
    if (newProducts.length < 5) {
      setHasMoreProducts(false);
    }
    setPage((prev) => prev + 1);
  };

  useEffect(() => {
    getAllProducts();
    scrollToTop();
  }, [dispatch]);

  return (
    <>
      <section className="stock_list_banner">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 align-self-center">
              <div className="stock_banner_slider carousel carousel-fade slide" id="heroCarousel" data-ride="carousel">
                <div id="carouselExampleAutoplaying" className="carousel slide" data-bs-ride="carousel">
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <div className="carousel-container">
                        <h2 className="lg_text animate__animated animate__fadeInLeft animate__slow">
                          GET THE BEST
                          <span className="d-block">DEALS ON USED</span>
                          <span className="d-block">EQUIPMENT</span>
                        </h2>
                        <div className="divider animate__animated animate__fadeInLeft animate__delay-0.5s"></div>
                        <h1 className="md_text" data-aos="fade-right" data-aos-delay={600} data-aos-duration={1500} style={{ fontWeight: "normal" }}>
                          Perfect Equipment for High <br />
                          Intensity Jobs
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="inventory_section py-5" style={{ background: "none" }}>
        <div className="container">
          <div className="title_with_sep text-center">
            <h3>USED CONSTRUCTION EQUIPMENT</h3>
            <div className="divider m-auto"></div>
          </div>
          {isLoading && page === 1 ? (
            <div className="text-center my-4">
              <Spinner />
            </div>
          ) : (
            <ProductCard products={stockList} />
          )}
          <div className="text-center">
            {isLoading && page > 1 ? (
              <Spinner />
            ) : (
              hasMoreProducts && (
                <button className="cbtn_dark mt-4 cursor" onClick={loadMoreProducts}>
                  View More
                </button>
              )
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default StockList;
