import React from 'react';
import SoldEquipment from '../../Components/Sold Equipment/soldEquipment';

const SoldRoller = () => {
    return (
        <div>
            <SoldEquipment cat="Roller" name="roller"/>
        </div>
    );
}

export default SoldRoller;
