import { Route, Routes } from 'react-router-dom';
import Footer from './Components/Footer/footer';
import Header from './Components/Header/header';
import Subscribe from './Components/Subscribe/subscribe';
import Home from './Pages/Home/home';
import './App.css';
import Caterpillar from './Pages/Brands/caterpillar';
import WhyGces from './Pages/Why Gces/whyGces';
import HeaderWithoutBg from './Components/Header without Bg/headerWithoutBg';
import Details from './Pages/Equipment/Details/details';
import StockList from './Pages/Stock List/stockList';
import AboutUS from './Pages/About Us/aboutUS';
import ContactUs from './Pages/Contact Us/contactUs';
import Videos from './Pages/Videos/videos';
import PrivacyPolicy from './Pages/Privacy Policy/privacyPolicy';
import ReturnPolicy from './Pages/Privacy Policy/returnPolicy';
import Page404 from './Pages/Page Not Found/page404';
import SubscribeEmail from './Pages/Subscribe/subscribe';
import SoldList from './Pages/Sold/soldList';
import WheelLoaders from './Pages/Equipment/wheelLoaders';
import Attachments from './Pages/Equipment/attachments';
import MegaSearch from './Pages/Search/megaSearch';
import Parts from './Pages/Equipment/parts';
import Backhoe from './Pages/Equipment/backhoe';
import Dozers from './Pages/Equipment/dozers';
import DumpTruck from './Pages/Equipment/dumpTruck';
import Excavators from './Pages/Equipment/excavators';
import MiniExcavators from './Pages/Equipment/miniExcavators';
import MotorGraders from './Pages/Equipment/motorGraders';
import Roller from './Pages/Equipment/roller';
import SkidSteer from './Pages/Equipment/skidSteer';
import Telehandler from './Pages/Equipment/Telehandler';
import TrackLoader from './Pages/Equipment/trackLoader';
import Case from './Pages/Brands/case';
import Volvo from './Pages/Brands/volvo';
import JohnDeere from './Pages/Brands/johnDeere';
import Hitachi from './Pages/Brands/hitachi';
import Doosan from './Pages/Brands/doosan';
import Komatsu from './Pages/Brands/komatsu';
import SideBar from './Components/Side Bar/sideBar';
import WheelExcavators from './Pages/Equipment/wheelExcavators';
import Shipping from './Pages/Services/shipping';
import Transport from './Pages/Services/transport';
import Inspections from './Pages/Services/inspections';
import SoldWheelLoaders from './Pages/Sold/soldWheelLoaders';
import SoldAttachments from './Pages/Sold/soldAttachments';
import SoldBackhoe from './Pages/Sold/soldBackhoe';
import SoldDozer from './Pages/Sold/soldDozer';
import SoldDumpTruck from './Pages/Sold/soldDumpTruck';
import SoldExcavators from './Pages/Sold/soldExcavators';
import SoldMiniExcavator from './Pages/Sold/soldMiniExcavator';
import SoldMotorGraders from './Pages/Sold/soldMotorGraders';
import SoldParts from './Pages/Sold/soldParts';
import SoldRoller from './Pages/Sold/soldRoller';
import SoldSkidSteer from './Pages/Sold/soldSkidSteer';
import SoldTelehandler from './Pages/Sold/soldTelehandler';
import SoldTrackLoader from './Pages/Sold/soldTrackLoader';
import SoldWheelExcavators from './Pages/Sold/soldWheelExcavators';
import Other from './Pages/Brands/other';

function App() {
  return (
    <>

      {/* <Header /> */}
      <HeaderWithoutBg />
      <SideBar />
      <Routes>
        <Route path='/' element={<Home />} />
        {/* Start All Category for Equipment */}
        <Route path='wheel-loaders' element={<WheelLoaders />} />
        <Route path='motor-graders' element={<MotorGraders />} />
        <Route path='excavators' element={<Excavators />} />
        <Route path='mini-excavator' element={<MiniExcavators />} />
        <Route path='dozer' element={<Dozers />} />
        <Route path='backhoe' element={<Backhoe />} />
        <Route path='roller' element={<Roller />} />
        <Route path='track-loader' element={<TrackLoader />} />


        <Route path='wheel-excavators' element={<WheelExcavators />} />
        <Route path='skid-steer' element={<SkidSteer />} />
        <Route path='telehandler' element={<Telehandler />} />
        <Route path='dump-truck' element={<DumpTruck />} />
        <Route path='attachments' element={<Attachments />} />
        <Route path='/parts' element={<Parts />} />
        {/* End All Category for Equipment */}

        {/* Start All Brands for Equipment */}
        <Route path='caterpillar' element={<Caterpillar />} />
        <Route path='case' element={<Case />} />
        <Route path='volvo' element={<Volvo />} />
        <Route path='john-deere' element={<JohnDeere />} />
        <Route path='hitachi' element={<Hitachi />} />
        <Route path='doosan' element={<Doosan />} />
        <Route path='komatsu' element={<Komatsu />} />
        <Route path='other' element={<Other />} />
        {/* End All Brands for Equipment */}

        {/* start our service */}
        <Route path='shipping' element={<Shipping />} />
        <Route path='transport' element={<Transport />} />
        <Route path='inspections' element={<Inspections />} />
        {/* End our service */}

        <Route path='sold-list' element={<SoldList />} />
        <Route path='sold/wheel-loaders' element={<SoldWheelLoaders />} />
        <Route path='sold/attachments' element={<SoldAttachments />} />
        <Route path='sold/backhoe' element={<SoldBackhoe />} />
        <Route path='sold/dozer' element={<SoldDozer />} />
        <Route path='sold/parts' element={<SoldParts />} />
        <Route path='sold/roller' element={<SoldRoller />} />
        <Route path='sold/telehandler' element={<SoldTelehandler />} />
        <Route path='sold/track-loader' element={<SoldTrackLoader />} />
        <Route path='sold/skid-steer' element={<SoldSkidSteer />} />
        <Route path='sold/wheel-excavators' element={<SoldWheelExcavators />} />
        <Route path='sold/dump-truck' element={<SoldDumpTruck />} />
        <Route path='sold/excavators' element={<SoldExcavators />} />
        <Route path='sold/mini-excavator' element={<SoldMiniExcavator />} />
        <Route path='sold/motor-graders' element={<SoldMotorGraders />} />


        <Route path='why-gces' element={<WhyGces />} />
        <Route path='details' element={<Details />} />
        <Route path='stock-list' element={<StockList />} />
        <Route path='about-us' element={<AboutUS />} />
        <Route path='contact-us' element={<ContactUs />} />
        <Route path='videos' element={<Videos />} />
        <Route path='privacy-policy' element={<PrivacyPolicy />} />
        <Route path='return-policy' element={<ReturnPolicy />} />
        <Route path='subscribe-email' element={<SubscribeEmail />} />
        <Route path='mega-search' element={<MegaSearch />} />
        <Route path='*' element={<Page404 />} />
      </Routes>

      <Routes>

      </Routes>
      <Subscribe />
      <Footer />
    </>
  );
}

export default App;
