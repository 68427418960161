import React from 'react';
import SoldEquipment from '../../Components/Sold Equipment/soldEquipment';

const SoldSkidSteer = () => {
    return (
        <div>
            <SoldEquipment cat="Skid Steer" name="skid-steer" />
        </div>
    );
}

export default SoldSkidSteer;
