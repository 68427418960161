import React from "react";
import Services from "../../Components/Services/services";
import Brands from "../../Components/Brands/brands";

const Inspections = () => {
  return (
    <>
      {/* Single Service  */}
      <section className="single_equipment py-lg-5 py-4">
        <div className="container">
          <div
            className="navigation_border mb-lg-4 mb-3 aos-init aos-animate"
            data-aos="fade-up"
          >
            <div className="row">
              <div className="col-lg-6 text-lg-left text-center pb-lg-3 pb-2">
                {/* <div className="navigation_btn">
                                <a href="" className="mr-2"><i className="bi bi-chevron-left fw-bold" /> heavy equipment dismantling </a> |
                                <a href="inspection-and-appraisal" className="ml-2"> Inspection &amp; Appraisal <i className="bi bi-chevron-right ml-2" /> </a>
                            </div> */}
              </div>
              <div className="col-md-6 text-md-right text-center pb-lg-3 d-none d-sm-block">
                <ul className="sort_breadcrums">
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>Inspections</li>
                </ul>
              </div>
            </div>
          </div>
          {/* service_content section */}

          <div className="an-content-banner-inspections aos-init aos-animate"></div>
          <div className="service_content">
            <div className="mb-4 text-center aos-init aos-animate">
              {/* <img src="./images/inspections.jpg" alt="Service" className=' lazyloaded img-banner' /> */}
            </div>
            <div className="aos-init aos-animate">
              <h2 className="aos-init aos-animate">
                Construction equipment inspections now
              </h2>
              <p className="aos-init aos-animate">
                When it comes to construction equipment evaluation, we want you
                to feel confident in the condition of your equipment. We will
                supply all essential documentation, including an in-depth
                condition report outlining any damage or wear on your apparatus.
                Our engineers can offer both obligatory and optional
                maintenance, as well as provide an accurate estimate of your
                equipment lifespan.
              </p>
              <p className="aos-init aos-animate">
                If your equipment has to be replaced right away, we can help you
                find high-quality used gear from our wide inventory. We are
                stockiest for some of the world's biggest manufacturers of
                construction and heavy gear, and everything of the equipment we
                offer has been thoroughly inspected and appraised.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* More Service  */}
      <Services />

      {/* Our Brands */}
      <Brands />
    </>
  );
};

export default Inspections;
