import React from 'react';
import SoldEquipment from '../../Components/Sold Equipment/soldEquipment';

const SoldDumpTruck = () => {
    return (
        <div>
            <SoldEquipment cat="Dump Truck" name="dump-truck"/>
           
        </div>
        
    );
}

export default SoldDumpTruck;
